/*  ==========================================================================
    QUOTE V4
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__quote-v5--background: $clrc-light-blue;
$module-clr__quote-v5--text: $clr__white;

/*
 * MODULE
 */

.bn-quote-v5 {
  .bn-grid__col {
    background-color: $module-clr__quote-v5--background;
  }
  .bn-quote-v5__frame {
    margin: 0 auto;
    padding: 150px 0;
    position: relative;
    justify-content: center;
    .bn-quote-v5__text {
      color: $module-clr__quote-v5--text;
      font-size: 4.5em;
      letter-spacing: -1.6px;
      line-height: 1.2;
      width: 100%;
      > p {
        &.text-center {
          text-align: center;
        }
        &.text-right {
          text-align: right;
        }
      }
    }
    .bn-quote-v5__author {
      margin-top: 5em;
      text-transform: uppercase;
      font-size: 1.4em;
      font-weight: $typo__fw--bold;
      > * {
        color: $module-clr__quote-v5--text;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// desktop small
@media #{$mq__desktop--small} {
  .bn-quote-v5 {
    .bn-quote-v5__frame {
      .bn-quote-v5__text {
        > p {
          &.text-right {
            text-align: center;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-quote-v5 {
    .bn-quote-v5__frame {
      .bn-quote-v5__text {
        font-size: 4em;
      }
      .bn-quote-v5__author {
        margin-top: 3em;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-quote-v5 {
    .bn-quote-v5__frame {
      .bn-quote-v5__text {
        font-size: 3.5em;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-quote-v5 {
    .bn-quote-v5__frame {
      .bn-quote-v5__text {
        font-size: 3em;
      }
    }
  }
}