/*  ==========================================================================
    TYPOGRAPHY
    Font settings concerning your project.

    INFO:
    - change your project related font family here
    - change secondary font family if you want a different font for headlines
    - fonts are included local via "webfontloader.js", look here: main.js
    - for help how to include a font local look here:
      "Resources/Public/admin/assets/fonts/README.txt"
    - if needed change also the font weight's
    ========================================================================== */

// font families
$typo__ff--primary: 'Jost', sans-serif;
$typo__ff--secondary: 'Bree', sans-serif;
$typo__ff--tertiary: 'Open Sans', sans-serif;

// font sizes
$typo__fs--button: 1.4em;;
$typo__fs--headline: 6.5em;
$typo__fs--headline-tablet: 4.6em;
$typo__fs--headline-phablet: 3.3em;
$typo__fs--headline-phone: 3.3em;
$typo__fs--headline-small: 3em;
$typo__fs--headline-small-tablet: 2.5em;
$typo__fs--headline-small-phablet: 2.1em;
$typo__fs--headline-small-phone: 2.1em;
$typo__fs--subheadline: 3em;
$typo__fs--subheadline-tablet: 2.5em;
$typo__fs--subheadline-phablet: 2.1em;
$typo__fs--subheadline-phone: 2.1em;
$typo__fs--overline: 1.4em;
$typo__fs--overline-phablet: 1.2em;
$typo__fs--overline-phone: 1.2em;
$typo__fs--text: 1.8em;

// font weights
$typo__fw--bold: 700;
$typo__fw--semibold: 600;
$typo__fw--regular: 400;
$typo__fw--light: 300;
