/*  ==========================================================================
    OFFERS SLIDER
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__offers-list-view--arrows: $clr__ci--primary;
$module-clr__offers-list-view--arrows-hover: $clr__ci--secondary;
$module-clr__offers-list-view--bg-primary: $clr__ci--primary;
$module-clr__offers-list-view--bg-secondary: $clr__ci--secondary;
$module-clr__offers-list-view--bg-tertiary: $clr__ci--tertiary;
$module-clr__offers-list-view--filter: $clr__ci--primary;
$module-clr__offers-list-view--filter-accent: $clr__ci--secondary;
$module-clr__offers-list-view--typo-primary: $clr__typo--secondary;
$module-clr__offers-list-view--typo-secondary: $clr__typo--secondary;
$module-clr__offers-list-view--typo-tertiary: $clr__typo--primary;

/*
 * MODULE
 */

.bn-offers-slider {
  overflow: hidden;
  .bn-grid__col {
    position: relative;
  }
  .bn-offers-filter {
    @include offers--filter($module-clr__offers-list-view--filter,$module-clr__offers-list-view--filter-accent);
  }
  .bn-offers-list-view__arrows {
    @include slider--arrows-v2($clrc-dark-blue,$clrc-light-blue);
    .bn-arrows__arrow {
      z-index: 1;
    }
  }
  .bn-offers-list-view__overline {
    align-items: center;
    margin-bottom: 30px;
  }
  .bn-offers-list-view__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .bn-offer-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 75px 0;
      .bn-offer-box__top {
        display: block;
        position: relative;
        width: 50%;
        .bn-offer-box__image {
          padding-left: 30px;
          img {
            @include img--responsive();
          }
        }
        .bn-offer-box__price {
          background-color: $clrc-light-orange;
          color: $clr__typo--secondary;
          height: 100px;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 20px;
          min-width: 150px;
          z-index: 5;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            position: relative;
            text-align: center;
          }
          .bn-offer-box__amount-addition {
            font-size: 1.6em;
          }
          .bn-offer-box__amount {
            font-size: 2.5em;
            font-weight: $typo__fw--bold;
            &.bn-offer-box__amount--bigger {
              font-size: 2.1em;
            }
          }
        }
      }
      .bn-offer-box__bottom {
        width: 50%;
        padding-right: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .bn-offer-box__title {
          @include typo--subheadline($module-clr__offers-list-view--typo-tertiary);
          margin-top: 25px;
        }
        .bn-offer-box__subtitle {
          @include typo--rte();
          margin-top: 10px;
        }
        .bn-offer-box__seasondatas {
          display: flex;
          margin-top: 20px;
          > div {
            font-size: 1.6em;
            text-transform: uppercase;
            color: $clrc-light-blue;
          }
        }
        .bn-offer-box__price {
          font-weight: $typo__fw--light;
          display: flex;
          margin-top: 25px;
          .bn-offer-box__pricebox {
            background-color: $clrc-dark-blue;
            color: $clr__white;
            padding: 10px 20px;
            text-align: center;
            > span {
              font-size: 1.6em;
            }
            .bn-offer-box__amount {
              font-size: 3em;
            }
          }
          .bn-offer-box__buttons {
            display: flex;
            flex-direction: column;
            > a {
              height: 50%;
              min-width: 200px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 20px;
              text-transform: uppercase;
              font-size: 1.6em;
              cursor: pointer;
              transition: all 0.5s;
              &.bn-offer-box__request {
                color: $clrc-light-blue;
                &:hover {
                  color: $clr__white;
                  background-color: $clrc-light-blue;
                }
              }
              &.bn-offer-box__book {
                color: $clr__white;
                background-color: $clrc-light-green;
                &:hover {
                  background-color: $clrc-dark-blue;
                }
              }
            }
          }
        }
        .bn-offer-box__button {
          margin-top: 10px;
          .bn-button {
            font-size: 1.6em;
            font-weight: $typo__fw--bold;
            text-transform: uppercase;
            color: $module-clr__offers-list-view--typo-tertiary;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .bn-pagination {
      margin-top: 40px;
      text-align: center;
      width: 100%;
      .bn-button {
        @include button--default();
      }
    }
    // enlarged
    &.bn-offers-list-view__holder--enlarged {
      .bn-offer-box {
        width: 100%;
        // remove margin top on first, second and third box
        &:nth-child(-n+3) {
          margin-top: 0;
        }
      }
    }
    // slidable
    &.bn-offers-list-view__holder--slidable {
      &.slick-slider {
        margin-left: -10px;
        width: calc(100% + 20px);
        .slick-slide {
          > div {
            height: 100%;
            .bn-offer-box {
              display: flex !important;
              height: 100%;
              margin: 0 10px !important;
              width: calc(100% - 20px) !important;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .bn-offers-list-view__dots {
    @include slider--dots-v2($clrc-dark-blue,6);
    margin-top: 30px;
  }
  .bn-offers-list-view__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrc-light-blue;
    margin-top: 40px;
    > span {
      font-weight: $typo__fw--bold;
      font-size: 16px;
      text-transform: uppercase;
    }
    > i {
      margin-left: 10px;
      font-size: 15px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  #bn-offer-box-37400,
  #bn-offer-box-37398 {
    .bn-offer-box__amount-hb {
      display: block;
    }
  }
  #bn-offer-box-37007 {
    .bn-offer-box__amount-bb {
      display: block !important;
    }
  }
  .bn-offer-box__amount-bb,
  .bn-offer-box__amount-hb {
    display: none;
  }

  &--overflow {
    .slick-slider {
      display: block!important;
    }
    .slick-list {
      overflow: visible;
    }
    .bn-offers-list-view__holder.bn-offers-list-view__holder--slidable.slick-slider .slick-slide > div .bn-offer-box {
      width: calc(100% - 145px)!important;
    }
    .bn-offers-list-view__arrows .bn-arrows__arrow.bn-arrows__arrow-next {
      right: 1em;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-offers-slider {
    .bn-offers-list-view__holder {
      .bn-offer-box {
        padding: 40px 0;
        .bn-offer-box__top {
          .bn-offer-box__price {
            min-width: 125px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-offers-slider {
    .bn-offers-list-view__arrows {
      display: none !important;
    }
    .bn-offers-list-view__holder {
      .bn-offer-box {
        flex-direction: column;
        .bn-offer-box__top {
          width: 100%;
          .bn-offer-box__image {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;
          }

        }
        .bn-offer-box__bottom {
          width: 100%;
          order: 2;
          padding-left: 0 !important;
          padding-right: 0 !important;
          .bn-offer-box__seasondatas {
            margin-top: 0px;
          }
          .bn-offer-box__button {
            .bn-button {
              display: block;
              min-width: 0;
              width: 100%;
            }
          }
        }
      }
      &.bn-offer-box--even {
        .bn-offer-box__top {
          .bn-offer-box__image {
            padding-right: 0;
          }
        }
      }
      // enlarged
      &.bn-offers-list-view__holder--enlarged {
        .bn-offer-box {
          &:nth-child(3) {
            margin-top: 10px;
          }
        }
      }
      // slidable
      &.bn-offers-list-view__holder--slidable {
        .bn-offer-box {
          padding: 0 50px;
          flex-direction: column !important;
          &.bn-offer-box--even {
            .bn-offer-box__bottom {
              order: 2;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
        .bn-offer-box__top {
          .bn-offer-box__price {
            padding: 15px;
            right: 0 !important;
            left: auto !important;
          }
        }
      }
    }

    &--overflow {
      .bn-offers-list-view__holder.bn-offers-list-view__holder--slidable .bn-offer-box.bn-offer-box--even .bn-offer-box__bottom {
        padding-right: 35px!important;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-offers-slider {
    .bn-offers-list-view__arrows {
      display: none !important;
    }
    .bn-offers-list-view__holder {
      display: block;
      .bn-offer-box {
        margin-top: 20px !important;
        width: 100%;
        .bn-offer-box__top {
          display: flex;
          flex-direction: column;
          height: auto;
          padding-top: 0;
          .bn-offer-box__image {
            height: auto;
            left: auto;
            order: 2;
            position: static;
            top: auto;
          }
          .bn-offer-box__price {
            padding: 5px;
            height: auto;
            .bn-offer-box__amount-addition,
            .bn-offer-box__amount {
              display: inline-block;
              font-size: 1.5em;
            }
            .bn-offer-box__amount-addition {
              margin: 0 5px;
            }
          }
        }
        .bn-offer-box__bottom {
          .bn-offer-box__price {
            .bn-offer-box__pricebox {
              padding: 10px;
              > span {
                font-size: 1.2em;
              }
            }
            .bn-offer-box__buttons {
              > a {
                min-width: auto;
                > span {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        // undo repositioning of image and text
        &.bn-offer-box--even {
          .bn-offer-box__top {
            order: 1;
          }
          .bn-offer-box__bottom {
            order: 2;
          }
        }
        // remove margin top on first box
        &:first-child {
          margin-top: 0 !important;
        }
      }
      // enlarged
      &.bn-offers-list-view__holder--enlarged {
        .bn-offer-box {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
      // slidable
      &.bn-offers-list-view__holder--slidable {
        .bn-offer-box {
          padding: 0;
        }
        &.slick-slider {
          margin-left: 0;
          width: 100%;
          .slick-slide {
            > div {
              .bn-offer-box {
                margin: 0 !important;
                width: 100% !important;
                .bn-offer-box__top {
                  .bn-offer-box__image {
                    padding-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-offers-slider {
    .bn-offers-list-view__holder {
      .bn-pagination {
        margin-top: 20px;
      }
    }
  }
}
