/*  ==========================================================================
    TEXT PIC BOX V2
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__text-pic-box-v2--bg-primary: $clr__ci--primary;
$module-clr__text-pic-box-v2--bg-secondary: $clr__ci--secondary;
$module-clr__text-pic-box-v2--bg-tertiary: $clr__ci--tertiary;
$module-clr__text-pic-box-v2--typo-primary: $clr__typo--primary;
$module-clr__text-pic-box-v2--typo-secondary: $clr__typo--primary;
$module-clr__text-pic-box-v2--typo-tertiary: $clr__typo--primary;
$module-clr__text-pic-box-v2--accent-primary: $clr__ci--secondary;
$module-clr__text-pic-box-v2--accent-secondary: $clr__ci--primary;
$module-clr__text-pic-box-v2--accent-tertiary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-text-pic-box-v2 {
  .bn-text-pic-box-v2__frame {
    justify-content: space-between;
    .bn-text-pic-box-v2__content,
    .bn-text-pic-box-v2__images {
      max-width: calc(50% - 10px);
      > div {
        width: 100%;
      }
    }
    .bn-text-pic-box-v2__content {
      align-items: center;
      display: flex;
      padding: 80px;
      .bn-text-pic-box-v2__headline {
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .bn-text-pic-box-v2__text {
        @include typo--table-lock();
      }
      .bn-text-pic-box-v2__button {
        margin-top: 20px;
      }
    }
    .bn-text-pic-box-v2__images {
      overflow: hidden;
      > div {
        height: 100%;
        position: relative;
      }
      .bn-text-pic-box-v2__image {
        display: block;
        img {
          @include img--cover-v2();
        }
        > div {
          height: 100%;
          position: relative;
          overflow: hidden;
          width: 100%;
        }
      }
      // output according to number of pictures
      &.bn-text-pic-box-v2__images--number-1 {
        .bn-text-pic-box-v2__image {
          height: 100%;
          left: 0;
          top: 0;
          width: 100%;
        }
      }
      &.bn-text-pic-box-v2__images--number-2,
      &.bn-text-pic-box-v2__images--number-3 {
        .bn-text-pic-box-v2__image {
          height: calc(50% - 10px);
          width: calc(30% - 10px);
          &.bn-text-pic-box-v2__image-1 {
            height: 100%;
            left: 0;
            top: 0;
            width: calc(70% - 10px);
          }
        }
      }
      &.bn-text-pic-box-v2__images--number-2 {
        .bn-text-pic-box-v2__image {
          &.bn-text-pic-box-v2__image-2 {
            bottom: 0;
            right: 0;
          }
        }
      }
      &.bn-text-pic-box-v2__images--number-3 {
        .bn-text-pic-box-v2__image {
          &.bn-text-pic-box-v2__image-2 {
            right: 0;
            top: 0;
          }
          &.bn-text-pic-box-v2__image-3 {
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
  // colors
  &.bn-text-pic-box-v2--clr-primary {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        .bn-text-pic-box-v2__overline {
          @include typo--overline($clr__typo--primary, $module-clr__text-pic-box-v2--accent-primary);
        }
        .bn-text-pic-box-v2__headline {
          @include typo--headline-small($module-clr__text-pic-box-v2--typo-primary);
          font-weight: $typo__fw--bold;
        }
        .bn-text-pic-box-v2__text {
          @include typo--rte($module-clr__text-pic-box-v2--typo-primary,$module-clr__text-pic-box-v2--accent-primary);
        }
        .bn-text-pic-box-v2__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box-v2--typo-primary,$module-clr__text-pic-box-v2--accent-primary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v2--clr-secondary {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        .bn-text-pic-box-v2__overline {
          @include typo--overline($clr__typo--primary, $module-clr__text-pic-box-v2--accent-secondary);
        }
        .bn-text-pic-box-v2__headline {
          @include typo--subheadline($module-clr__text-pic-box-v2--typo-secondary);
          font-size: 3.3em;
        }
        .bn-text-pic-box-v2__text {
          @include typo--rte($module-clr__text-pic-box-v2--typo-secondary,$module-clr__text-pic-box-v2--accent-secondary);
        }
        .bn-text-pic-box-v2__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box-v2--typo-secondary,$module-clr__text-pic-box-v2--accent-secondary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v2--clr-tertiary {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        .bn-text-pic-box-v2__overline {
          @include typo--overline($clr__typo--primary, $module-clr__text-pic-box-v2--accent-tertiary);
        }
        .bn-text-pic-box-v2__headline {
          @include typo--subheadline($module-clr__text-pic-box-v2--typo-tertiary);
          font-size: 3.3em;
        }
        .bn-text-pic-box-v2__text {
          @include typo--rte($module-clr__text-pic-box-v2--typo-tertiary,$module-clr__text-pic-box-v2--accent-tertiary);
        }
        .bn-text-pic-box-v2__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box-v2--typo-tertiary,$module-clr__text-pic-box-v2--accent-tertiary);
          }
        }
      }
    }
  }
  // mirror
  &.bn-text-pic-box-v2--mirror {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        order: 2;
      }
      .bn-text-pic-box-v2__images {
        order: 1;
        // output according to number of pictures
        &.bn-text-pic-box-v2__images--number-2,
        &.bn-text-pic-box-v2__images--number-3 {
          .bn-text-pic-box-v2__image {
            &.bn-text-pic-box-v2__image-1 {
              left: auto;
              right: 0;
            }
          }
        }
        &.bn-text-pic-box-v2__images--number-2 {
          .bn-text-pic-box-v2__image {
            &.bn-text-pic-box-v2__image-2 {
              left: 0;
              right: auto;
            }
          }
        }
        &.bn-text-pic-box-v2__images--number-3 {
          .bn-text-pic-box-v2__image {
            &.bn-text-pic-box-v2__image-2 {
              left: 0;
              right: auto;
            }
            &.bn-text-pic-box-v2__image-3 {
              left: 0;
              right: auto;
            }
          }
        }
      }
    }
  }
  // spacing to next text pic element
  + .bn-text-pic-box-v2 {
    margin-top: 20px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-text-pic-box-v2 {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        padding: 40px;
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-text-pic-box-v2 {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        padding-left: 20px;
        padding-right: 20px;
        .bn-text-pic-box-v2__headline {
          margin-bottom: 20px;
        }
      }
      .bn-text-pic-box-v2__images {
        // output according to number of pictures
        &.bn-text-pic-box-v2__images--number-2,
        &.bn-text-pic-box-v2__images--number-3 {
          .bn-text-pic-box-v2__image {
            height: calc(30% - 10px);
            width: calc(50% - 10px);
            &:first-child {
              height: calc(70% - 10px);
              width: calc(100%);
            }
          }
        }
        &.bn-text-pic-box-v2__images--number-2 {
          .bn-text-pic-box-v2__image {
            &.bn-text-pic-box-v2__image-2 {
              left: 0;
              right: auto;
            }
          }
        }
        &.bn-text-pic-box-v2__images--number-3 {
          .bn-text-pic-box-v2__image {
            &.bn-text-pic-box-v2__image-2 {
              bottom: 0;
              left: 0;
              right: auto;
              top: auto;
            }
          }
        }
      }
    }
    // mirror
    &.bn-text-pic-box-v2--mirror {
      .bn-text-pic-box-v2__frame {
        .bn-text-pic-box-v2__images {
          // output according to number of pictures
          &.bn-text-pic-box-v2__images--number-2 {
            .bn-text-pic-box-v2__image {
              &.bn-text-pic-box-v2__image-2 {
                left: auto;
                right: 0;
              }
            }
          }
          &.bn-text-pic-box-v2__images--number-3 {
            .bn-text-pic-box-v2__image {
              &.bn-text-pic-box-v2__image-3 {
                left: auto;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-text-pic-box-v2 {
    .bn-text-pic-box-v2__frame {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .bn-text-pic-box-v2__content,
      .bn-text-pic-box-v2__images {
        display: block;
        max-width: 100%;
      }
      .bn-text-pic-box-v2__content {
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
      }
      .bn-text-pic-box-v2__images {
        > div {
          align-items: stretch;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          height: auto;
          justify-content: space-between;
        }
        .bn-text-pic-box-v2__image {
          bottom: auto !important;
          display: none;
          height: auto !important;
          left: auto !important;
          padding-top: 10px;
          position: static;
          right: auto !important;
          top: auto !important;
          width: calc(50% - 5px) !important;
          &:nth-child(-n+2){
            display: block;
          }
          &:first-child:nth-last-child(1) {
            width: 100% !important;
          }
        }
      }
    }
    // spacing to next text pic element
    + .bn-text-pic-box-v2 {
      margin-top: 10px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-text-pic-box-v2 {
    .bn-text-pic-box-v2__frame {
      .bn-text-pic-box-v2__content {
        padding: 20px;
      }
    }
  }
}

