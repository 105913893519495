/*  ==========================================================================
    TOP WINDOW LINKS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__top-window-links--bg-primary: $clr__ci--primary;
$module-clr__top-window-links--bg-secondary: $clr__white;
$module-clr__top-window-links--typo-primary: $clr__typo--secondary;
$module-clr__top-window-links--typo-secondary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-top-window-links {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  .bn-top-window-links--box {
    position: relative;
    flex: 1 0 calc(50% - 2px);
    max-width: calc(50% - 2px);
    margin-top: 14px;
    padding-bottom: calc((36% / 2) + 1px);
    .bn-top-window-links--link {
      display: block;
      .bn-top-window-links__image {
        img {
          width: 100%;
        }
      }
      .bn-top-window-links--overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba($clr__black, .65);
        .bn-top-window-links--title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%)translateY(-50%);
          font-weight: $typo__fw--semibold;
          color: $module-clr__top-window-links--typo-primary;
          font-size: 1.5em;
          text-transform: uppercase;
          text-align: center;
        }
        &.bn-top-window-links--no-image {
          background: rgba($module-clr__top-window-links--bg-secondary, .5);
          .bn-top-window-links--title {
            color: $module-clr__top-window-links--typo-secondary;
          }
          &:hover {
            background: rgba($module-clr__top-window-links--bg-secondary, .8);
          }
        }
      }
    }
    &:hover {
      .bn-top-window-links--overlay {
        background: rgba($module-clr__top-window-links--bg-primary, .5);
      }
    }
  }
}

.bn-mobile-header {
  .bn-top-window-links {
    background: $module-clr__top-window-links--bg-primary;
    padding: 30px 20px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// custom
@media (max-height: 840px) and (max-width: 1920px) and (min-width: 1366px) {
  .bn-top-window-links {
    display: none;
  }
}

// custom
@media (max-height: 660px) and (max-width: 1366px) and (min-width: 1024px) {
  .bn-top-window-links {
    display: none;
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-top-window-links {
    display: flex;
    .bn-top-window-links--box {
      flex: 1 0 calc(50% - 7px);
      max-width: calc(50% - 7px);
      padding-bottom: 0;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-top-window-links {

  }
}
