/*  ==========================================================================
    Webcam
    ========================================================================== */

/*
 * MODULE
 */

.bn-webcam {
  iframe {
    pointer-events: none;
  }
  &__bg {
    animation-duration: 1s;
    animation-delay: 2s;
    animation-name: hide;
    animation-fill-mode:forwards;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-wrap {
    position: relative;
    display: inline-block;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 1;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-webcam {

  }
}

// tablet
@media #{$mq__tablet} {
  .bn-webcam {

  }
}

// phablet
@media #{$mq__phablet} {
  .bn-webcam {

  }
}

// phone
@media #{$mq__phone} {
  .bn-webcam {

  }
}
