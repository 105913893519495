/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.bn-footer {
  position: relative;
  visibility: visible !important;
  z-index: $unit__zi--footer;
  // type 1
  &.bn-footer--v1 {
    @include footer--v1();
  }
  // type 2
  &.bn-footer--v2 {
    //@include footer--v2();
  }
  // type 3
  &.bn-footer--v3 {
    //@include footer--v3();
  }
  // type 4
  &.bn-footer--v4 {
    @include footer--v4();
  }
  // top references
  .bn-top-references {
    &.bn-top-references--mobile {
      display: none;
      position: fixed;
      bottom: 30px;
      left: 30px;
      z-index: $unit__zi--footer-top-references;
      .bn-top-references__item {
        color: $clr__typo--secondary;
        cursor: pointer;
        display: none;
        font-size: 3em;
        height: 60px;
        text-align: center;
        width: 60px;
        margin-right: 5px;
        .bn-icon {
          &.bn-icon--minus {
            display: none;
          }
        }
        &.bn-top-references__item--static {
          display: flex;
          align-items: center;
          justify-content: center;
          &--mobile {
            background-color: $clrc-dark-blue;
          }
          &--mail {
            background-color: $clrc-light-blue;
          }
          &--newsletter {
            display: none;
          }
          &--prenota {
            width: auto;
            font-size: 1.8em;
            text-transform: uppercase;
            padding: 0 30px;
            background-color: $clrc-light-green;
          }
        }
        &.bn-top-references__item--visible {
          display: block;
        }
        &.bn-top-references__item--trigger {
          display: block;
          &.bn-top-references__item--act {
            .bn-icon {
              &.bn-icon--plus {
                display: none;
              }
              &.bn-icon--minus {
                display: inline-block;
              }
            }
          }
        }
        &:first-child {
          margin-top: 0;
        }
        &:hover {
          background-color: $clr__ci--secondary;
        }
      }
    }
  }
  // print address
  .bn-address-print {
    display: none;
  }
}

body.qr-disabled {
  .bn-footer {
    .bn-top-references {
      display: none !important;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// 370px max height
@media #{$mq__max-h--370} {
  .bn-footer {
    // top references
    .bn-top-references {
      display: none !important;
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        display: flex;
        &.bn-top-references--visible {
          display: flex;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        bottom: 10px;
        left: 10px;
        .bn-top-references__item {
          font-size: 2.5em;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
