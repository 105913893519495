/*  ==========================================================================
    QUICK REQUEST V2
    ========================================================================== */

@mixin quick-request--v2() {
  position: fixed;
  right: 0;
  top: 12em;
  z-index: 35;
  min-width: 34em;
  max-width: 34em;
  #bn_quickrequest_vertical .bn-form__button {
    background-color: $clr__cta--primary!important;
    &:hover {
      color: $clr__cta--primary!important;
      background-color: $clr__white!important;
    }
  }
  > a {
    padding: 2em 0;
    > i {
      font-size: 1.6em;
      margin-left: 25px;
    }
  }
  &.bn-quick-request--big {
    height: calc(65% + 35px);
    width: 600px;
  }
  .bn-form-quickrequest {
    padding: 0;
  }
  .bn-quick-request__title {
    opacity: 1;
  }
  .bn-quick-request__title,
  .bn-quick-request__form {
    background-color: $clr__ci--primary;
    color: $clr__typo--secondary;
    width: 100%;
  }
  .bn-quick-request__title {
    display: flex;
    background-color: #eeaf8e;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: background-color 0.25s ease-in-out;
    > div {
      padding: 0 20px 0 20px;
      position: relative;
      width: 100%;
    }
    span {
      font-size: 2.1em;
      letter-spacing: 2.3px;
      text-transform: uppercase;
      font-weight: $typo__fw--bold;
    }
    .bn-quick-request__arrows {
      position: absolute;
      right: 20px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      .bn-icon {
        font-size: 3.5em;
        height: 40px;
        line-height: 40px;
        &.bn-icon--arrow-bordered-top {
          display: none;
        }
        &.bn-icon--close {
          display: none;
        }
      }
    }
    &.bn-quick-request__title--act {
      opacity: 1;
      .bn-quick-request__arrows {
        .bn-icon {
          &.bn-icon--arrow-bordered-bottom {
            display: none;
          }
          &.bn-icon--arrow-bordered-top {
            display: inline-block;
          }
          &.bn-icon--close {
            display: none;
          }
        }
      }
    }
    &:hover {
      opacity: 1;
      background-color: rgba($clr-_cta--primary, 0.75);
    }
  }
  .bn-quick-request__form {
    @include scrollbar($clr__scrollbar,5px);
    height: 100%;
    display: none;
    margin-top: 5px;
    overflow-y: auto;
    padding: 10px 20px 20px;
    padding-bottom: 100px;
    &.of_visible {
      overflow: visible;
    }
    > div {
      @include quick-request--vertical();
    }
    .bn-loader {
      color: $clr__typo--secondary;
    }
    &.bn-quick-request__form--visible {
      display: block;
    }
  }
  &.bn-quick-request--hideable {
    &.bn-quick-request--hide {
      display: none;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  //// 900px max height
  //@media #{$mq__max-h--900} {
  //  &.bn-quick-request--big {
  //    height: calc(55% + 35px);
  //  }
  //}
  //// 700px max height
  //@media #{$mq__max-h--700} {
  //  &.bn-quick-request--big {
  //    height: calc(50% + 35px);
  //  }
  //  top: 50%;
  //}
  @media screen and (max-height: 840px) {
    top: 12em;
    right: 0;
    bottom: auto;
    left: auto;
    &.bn-quick-request--hideable.bn-quick-request--hide {
      display: block;
    }
    &.bn-quick-request--big {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      bottom: auto;
      margin-top: 0;
      z-index: 50;
    }
    .bn-quick-request__title {
      opacity: 1;
      .bn-quick-request__arrows {
        .bn-icon {
          &.bn-icon--arrow-bordered-bottom {
            display: none;
          }
          &.bn-icon--arrow-bordered-top {
            display: inline-block;
          }
          &.bn-icon--close {
            display: none;
          }
        }
      }
      &.bn-quick-request__title--act {
        .bn-quick-request__arrows {
          .bn-icon {
            &.bn-icon--arrow-bordered-bottom {
              display: none;
            }
            &.bn-icon--arrow-bordered-top {
              display: none;
            }
            &.bn-icon--close {
              display: inline-block;
            }
          }
        }
      }
    }
    .bn-quick-request__form {
      height: 100%;
      margin-top: 0;
      .bn-quick-request__form--visible {

      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    top: auto;
    right: auto;
    bottom: 11em;
    left: 0;
    min-width: 20em;
    max-width: 20em;
    height: 50px;
    z-index: 10;
    i {
      display: none;
    }
    &.bn-quick-request--hideable.bn-quick-request--hide {
      display: block;
    }
    &.bn-quick-request--big {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      bottom: auto;
      margin-top: 0;
      z-index: 50;
    }
    .bn-quick-request__title {
      opacity: 1;
      height: 50px;
      span {
        letter-spacing: normal;
      }
      .bn-quick-request__arrows {
        .bn-icon {
          &.bn-icon--arrow-bordered-bottom {
            display: none;
          }
          &.bn-icon--arrow-bordered-top {
            display: inline-block;
          }
          &.bn-icon--close {
            display: none;
          }
        }
      }
      &.bn-quick-request__title--act {
        .bn-quick-request__arrows {
          .bn-icon {
            &.bn-icon--arrow-bordered-bottom {
              display: none;
            }
            &.bn-icon--arrow-bordered-top {
              display: none;
            }
            &.bn-icon--close {
              display: inline-block;
            }
          }
        }
      }
    }
    .bn-quick-request__form {
      height: 100%;
      margin-top: 0;
      .bn-quick-request__form--visible {

      }
    }
  }
  @media #{$mq__phablet} {
    width: 140px;
    bottom: 8em;
    .bn-quick-request__title {
      height: 100%;
      span {
        font-size: 1.4em;
      }
      > div {
        padding: 10px 20px;
      }
    }
  }
}
