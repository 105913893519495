/*  ==========================================================================
    BEST PRICE
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__bestprice--primary: $clr__ci--primary;
$module-clr__bestprice--cta-primary: $clr__cta--primary;
$module-clr__bestprice--secondary: $clr__ci--secondary;
$module-clr__bestprice--white: $clr__white;
$module-clr__bestprice--grey: $clr__qr;

/*
 * MODULE
 */

.bn-bestprice {
  position: absolute;
  right: 0;
  top: 25.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
  .bn-module {
    &__box {
      background-color: $module-clr__bestprice--white;
      min-width: 34em;
      max-width: 34em;
      padding: 2.5em 5em;
      margin-bottom: 2.5em;
      display: flex;
      justify-content: space-between;
      text-align: right;
      &-title {
        color: $clrc-light-green;
        text-transform: uppercase;
        > span {
          font-size: 2.1em;
          line-height: 1;
        }
      }
      &-text {
        color: $module-clr__bestprice--grey;
        font-size: 1.6em;
      }
      &-right {
        img {
          width: 5em;
        }
      }
    }
  }
  &-trigger {
    display: none;
    position: relative;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    background: $clr__cta--primary;
    color: white;
    padding: 0;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 2.2em;
      color: $module-clr__bestprice--white;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media #{$mq__tablet}{
  .bn-bestprice {
    display: none;
    right: auto;
    left: 2em;
    bottom: 2em;
    width: 35em;
    &-wrapper {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s;
      .bn-module {
        &__box {
          &-title {
            > span {
              font-size: 1.7em;
            }
          }
          &-text {
            font-size: 1.3em;
          }
        }
      }
    }
    &-trigger {
      display: flex;
      .icon {
        transform: rotate(0);
        transition: all 0.5s;
      }
    }
    &.js-active {
      .bn-bestprice {
        &-wrapper {
          opacity: 1;
          pointer-events: all;
        }
        &-trigger {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-bestprice {
    width: 27em;
    &-wrapper {
      .bn-module {
        &__box {
          padding: 1.5em;
          &-right {
            display: none;
          }
          &-title {
            > span {
              font-size: 1.7em;
            }
          }
          &-text {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}