/*  ==========================================================================
    BEST PRICE
    ========================================================================== */

/*
 * MODULE
 */

.bn-included-services {
  .bn-grid__row {
    width: 100%;
  }

  .bn-included-services__features {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 40px -10px 0 -10px;
  }

  .bn-included-services__feature__box {
    width: 33.33%;
    padding: 10px;

    .bn-included-services__feature__wrap {
      border: 1px solid #E3DFD3;
      padding: 15px;
      align-items: center;
      display: flex;
    }

    .bn-icon {
      font-size: 3.5em;
      margin-right: 20px;
    }

    span {
      font-size: $typo__fs--text;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media #{$mq__tablet}{
  .bn-included-services {
    .bn-included-services__feature__box {
      width: 50%;
      span {
        font-size: 1.3em;
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-included-services {
    .bn-included-services__features {
      margin: 20px 0 0 0;

      .bn-included-services__feature__box {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }
}