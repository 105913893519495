/*  ==========================================================================
    MISC
    Styling for various unclassifiable things which are mostly at the bottom
    of the page, after the footer.
    ========================================================================== */

// scroll up
.bn-scroll-up {
  background-color: $clr__ci--secondary;
  border: 1px solid $clr__white;
  border-radius: 100%;
  color: $clr__typo--secondary;
  cursor: pointer;
  display: none;
  font-size: 2.5em;
  height: 50px;
  line-height: 48px;
  position: fixed;
  right: -100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  visibility: hidden;
  width: 50px;
  z-index: $unit__zi--scroll-up;
  &:hover {
    background-color: $clr__ci--primary;
  }
  &.bn-scroll-up--activated {
    display: block;
    &.bn-scroll-up--visible {
      right: 40px;
      visibility: visible;
    }
  }
}
// quick request
.bn-quick-request {
  visibility: visible !important;
  &.bn-quick-request--v1 {
    //@include quick-request--v1();
  }
  &.bn-quick-request--v2 {
    @include quick-request--v2();
    #bn_quickrequest_vertical .bn-form__button {
      border-color: $clr__white!important;
    }
  }
}
.bn-booking-button {
  margin-top: -50px;
  position: fixed;
  right: 0;
  top: 35vh;
  width: 260px;
  z-index: 35;
  background-color: $clr__cta--primary;
  border: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 68px;
  text-align: right;
  transform: translateY(-100%);
  > a {
    padding: 0 80px 0 20px;
    position: relative;
    width: 100%;
    color: $clr__white;
  }
  span {
    font-size: 2.2em;
    text-transform: uppercase;
    font-weight: $typo__fw--bold;
  }
  .bn-booking-button__icon {
    position: absolute;
    right: 20px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    .bn-icon {
      font-size: 4em;
      height: 40px;
      line-height: 40px;
    }
  }
  &:hover {
    background-color: $clr__cta--secondary;
  }

  &.minimized {
    width: 80px;
    //transition: all .5s;
    > a {
      padding: 0;
    }
    span {
      width: 0;
      display: block;
      overflow: hidden;
    }
    &:hover {
      width: 260px;
      > a {
        padding: 0 80px 0 20px;
      }
      span {
        width: auto;
        display: block;
        overflow: visible;
      }
    }
  }
}
body {
  &.header-v4 {
    .bn-quick-request {
      &.bn-quick-request--v1 {
        //@include quick-request--v1();
      }
    }
  }
}
// ehotelier pop up
.bn-ehotelier-pop-up {
  bottom: 0;
  height: 100%;
  left: 0;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  transition: max-height 0.5s;
  transition-delay: 0.5s;
  width: 100%;
  z-index: $unit__zi--pop-up;
  > .bn-grid {
    padding: 40px 40px 0 40px;
  }
  .bn-ehotelier-pop-up__overlay {
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.5s;
    transition-delay: 0s;
    width: 100%;
    z-index: 5;
  }
  .bn-ehotelier-pop-up__paper {
    background-color: $clr__white;
    box-shadow: 0 0 2px 0 rgba($clr__black,0.4);
    height: calc(100vh - 40px);
    position: relative;
    z-index: 10;
    > div {
      background-color: rgba($clr__ci--primary,0.2);
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 40px 40px 0 40px;
    }
    .bn-ehotelier-pop-up__head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      margin-bottom: 40px;
      white-space: nowrap;
      .bn-ehotelier-pop-up__close {
        color: $clr__ci--primary;
        cursor: pointer;
        display: inline-block;
        font-size: 3.5em;
        width: 50px;
        &:hover {
          color: $clr__ci--secondary;
        }
      }
      .bn-ehotelier-pop-up__controls {
        @include clearfix();
        //width: calc(100% - 50px);
        display: flex;
        flex-direction: row;
        .bn-ehotelier-pop-up__arrows {
          @include slider--arrows($clr__ci--primary);
          display: inline-block;
          float: right;
          .bn-arrows__arrow {
            font-size: 3.5em;
            .bn-icon {
              font-size: 1.0em;
            }
            &.bn-arrows__arrow-next {
              margin-left: 10px;
            }
          }
        }
        .bn-ehotelier-pop-up__control-info {
          color: $clr__ci--primary;
          display: inline-block;
          float: right;
          font-size: 1.6em;
          margin-left: 10px;
        }
      }
    }
    .bn-ehotelier-pop-up__holder {
      @include offers--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary);
      @include rooms--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary,$clr__ci--secondary,$clr__typo--secondary);
      @include scrollbar($clr__scrollbar,5px);
      max-height: 100%;
      overflow-y: auto;
      .bn-offer-box,
      .bn-room-box {
        padding-bottom: 40px;
      }
    }
  }
  &.bn-ehotelier-pop-up--open {
    max-height: 100%;
    transition-delay: 0s;
    .bn-ehotelier-pop-up__overlay {
      background-color: rgba($clr__black,0.8);
      transition-delay: 0.5s;
    }
  }
}
// newsletter pop up
.bn-newsletter-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($clr__black, 0.9);
  z-index: 999;
  &__wrap {
    background-color: $clr__white;
    padding: 50px;
    max-width: 70em;
    max-height: 100%;
    .bn-newsletter__title {
      font-size: 2em;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    #bn_newsletter_v1 {
      .bn-form__button-container {
        .bn-form__button {
          border-radius: 0;
          background-color: $clrc-dark-blue !important;
          color: $clr__white !important;
          &:hover {
            border-color: $clrc-dark-blue !important;
            background-color: $clr__white !important;
            color: $clrc-dark-blue !important;
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// 900px max height
@media #{$mq__max-h--900} {
  .bn-booking-button {
    top: 45vh;
  }
}
// 700px max height
@media #{$mq__max-h--700} {
  .bn-booking-button {
    top: 50vh;
  }
}
// 600px max height
@media #{$mq__max-h--600} {
  .bn-booking-button {
    transform: translateY(-50%);
    margin-top: 0;
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-booking-button {
    display: none;
  }
  // scroll up
  .bn-scroll-up {
    bottom: 40px;
    font-size: 2.0em;
    left: -100%;
    right: auto;
    top: auto;
    transform: none;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 40px;
        right: auto;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-top: 20px;
      }
      .bn-ehotelier-pop-up__head {
        margin-bottom: 20px;
      }
    }
  }
  .bn-newsletter-pop-up {
    &__wrap {
      width: 100%;
    }
  }
}

// phablet
@media #{$mq__phablet} {
  // scroll up
  .bn-scroll-up {
    bottom: 20px;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 20px;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    > .bn-grid {
      padding: 20px 20px 0 20px;
    }
    .bn-ehotelier-pop-up__paper {
      .bn-ehotelier-pop-up__head {
        .bn-ehotelier-pop-up__controls {
          .bn-ehotelier-pop-up__control-info {
            display: none;
          }
        }
      }
    }
  }
}
