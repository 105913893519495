/*  ==========================================================================
    MOBILE HEADER V5
    ========================================================================== */

@mixin mobile-header--v5() {
  @include mobile-header--v1();
  // controls
  .bn-controls {
    .bn-controls__left {
      order: 2;
      .bn-controls__left___top,
      .bn-controls__left___bottom {
        > .bn-grid__col {
          align-items: flex-end;
        }
      }
    }
    &__cta {
      display: flex;
      align-items: center;
    }
  }
  .bn-main-logo {
    &.bn-main-logo--above {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        margin-left: 0;
        margin-right: -5px;
      }
    }
  }
  // home icon
  .bn-home-icon {
    display: inline-flex;
    border: 1px solid $clr__white;
    padding: 10px;
    color: $clr__white;
    > i {
      font-size: 1.6em;
    }
  }
  // top references
  .bn-top-references {
    display: flex;
    align-items: center;
    gap: 2em;
    .bn-top-references__item {
      display: flex;
      justify-content: center;
      align-items: center;
      .bn-icon {
        font-size: 2.5em;
        color: $clr__white;
      }
    }
  }
  // control bar
  .bn-control-bar {
    .bn-control-bar__top,
    .bn-control-bar__center,
    .bn-control-bar__bottom {
      margin-left: calc(100% - #{$unit__wdt--mobile-header-control-bar});
    }
    .bn-control-bar__center {
      .bn-lang-menu {
        padding: 0 25px;
        margin-left: 15px;
      }
    }
    .bn-control-bar__top {
      position: relative;
      border-top: 3px solid $clrc-light-green;
      > .bn-grid__col {
        flex-direction: row;
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    // burger menu
    .bn-burger-menu {
      border-left-color: $clr__ci--primary;
      border-right-color: transparent;
      left: auto;
      right: 0;
    }
  }

  @media #{$mq__phablet} {
    .bn-lang-menu.bn-lang-menu--above {
      display: none;
    }

    .bn-top-references {
      &__item {
        &--2 {
          display: none !important;
        }
      }
    }
  }
}