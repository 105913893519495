/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity
$clr__ci--primary: #2482AA;
$clr__ci--secondary: #b9cc42;
$clr__ci--tertiary: #ddd;

// typography
$clr__typo--primary: #20222B;
$clr__typo--secondary: #FFFFFF;
$clr__typo--tertiary: #D8D4D3;

// call to action
//$clr__cta--primary: #DDA741;
$clr__cta--primary: #EEAF8E;
$clr__cta--secondary: #FF9133;

// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * CUSTOM
 */

$clr__gray-button: #A5A5A5;
$clr__gray-button--hover: #727272;
$clr__qr: #4A4A4A;
$clrc-light-green: #B9CC42;
$clrc-light-blue: #2482AA;
$clrc-dark-blue: #20222B;
$clrc-light-orange: #EEAF8E;
$clrc-light-background: #FCF8F3;
$clrc-golden: #F6C67A;
$clrc-river-bed: #3E4A56;
$clrc-alto: #D7D7D7;
$clrc-terracotta: #C9643C;

/*
 * SPECIFIC
 */

$clr__page: $clr__white;
$clr__progress-bar: $clrc-light-blue;
$clr__scrollbar: $clrc-dark-blue;
$clr__selection: $clr__gray;
