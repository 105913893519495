/*  ==========================================================================
    ROOMS LIST VIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__rooms-teaser--accent: $clr__ci--secondary;
$module-clr__rooms-teaser--bg: $clr__ci--primary;
$module-clr__rooms-teaser--typo: $clr__typo--secondary;

/*
 * MODULE
 */

.bn-rooms-teaser {
  .bn-grid__col {
    position: relative;
  }
  .bn-rooms-teaser__tabs {
    padding-bottom: 70px;
    text-align: center;
    > div {
      display: inline-block;
      padding: 10px;
    }
    .bn-button {
      @include button--reset();
      @include button--default-inverted();
      &.bn-button--act {
        background-color: $clr__ci--primary !important;
        border-color: $clr__ci--primary !important;
        color: $clr__typo--secondary !important;
      }
    }
  }
  .bn-rooms-teaser__overline {
    align-items: center;
    margin-bottom: 30px;
  }
  .bn-rooms-teaser__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-room-box {
      color: $module-clr__rooms-teaser--typo;
      margin-top: 40px;
      padding-top: 80px;
      width: 100%;
      &:first-child {
        padding-top: 0;
      }
      a {
        color: $module-clr__rooms-teaser--typo;
        &:visited {
          color: $module-clr__rooms-teaser--typo;
        }
        &:hover {
          color: $module-clr__rooms-teaser--typo;
        }
      }
      > div {
        display: flex;
        position: relative;
        padding-bottom: 2.5em;
      }
      &:nth-child(even) {
        > div {
          flex-direction: row-reverse;
          .bn-room-box__content {
            margin-right: 3em;
            margin-left: 0;
          }
        }
        .bn-room-box__labels {
          left: auto;
          right: 0;
        }
      }
      .bn-room-box__image {
        position: relative;
        background-color: $clr__black;
        flex: 1;
        img {
          @include img--cover-v2();
        }
      }
      .bn-room-box__content {
        display: flex;
        align-items: center;
        flex: 1;
        position: relative;
        z-index: 5;
        margin-left: 3em;
        margin-right: 0;
        background-color: $clrc-dark-blue;
        .bn-room-box__new {
          position: absolute;
          right: 1em;
          top: 1em;
          padding: 10px 20px;
          align-self: flex-end;
          font-size: 2.1em;
          background-color: $clr__white;
          color: $clrc-light-orange;
          text-transform: uppercase;
          font-weight: $typo__fw--bold;
          pointer-events: none;
        }
        > div {
          padding: 0 7em;
        }
        .bn-room-box__name {
          @include typo--overline($clr__white, $clr__white);
        }
        .bn-room-box__description {
          position: relative;
          font-size: 1.8em;
          margin-top: 2.5em;
          --bg-color: #20222b;
        }
        .bn-room-box__stats {
          font-size: 1.4em;
          margin-top: 70px;
          .bn-room-box__persons {
            text-transform: uppercase;
          }
        }
        .bn-room-box__button {
          margin-top: 3em;
          .bn-button {
            color: $clr__white;
            background-color: transparent;
            border: 1px solid $clr__white;
            padding: 1em 3em;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            max-width: 23em;
            cursor: pointer;
            transition: opacity 0.5s;
            > span {
              font-size: 14px;
              font-weight: $typo__fw--semibold;
            }
            > i {
              display: inline-block;
              font-size: 15px;
              transition: transform 0.5s;
            }
            &:hover {
              opacity: 0.75;
              > i {
                transform: translateX(5px);
              }
            }
          }
        }
        .bn-room-box__book {
          position: absolute;
          right: 1em;
          bottom: -2.5em;
          display: flex;
          flex-direction: column;
          .bn-button--request {
            @include button--cta();
            background-color: $clr__cta--primary;
            border-color: transparent !important;
            height: 50px;
            font-weight: $typo__fw--regular;
            font-size: 16px;
            padding: 0 20px;
            transition: all 0.25s ease-in-out;
            .bn-icon {
              margin-left: 15px;
              font-size: 18px;
            }
            &:hover {
              background-color: rgba($clr__cta--primary, 0.75);
              color: $clr__white !important;
            }
          }
          .bn-button--book {
            @include button--cta();
            background-color: $clrc-light-green;
            border-color: transparent !important;
            height: 50px;
            font-weight: $typo__fw--regular;
            font-size: 16px;
            padding: 0 20px;
            transition: all 0.25s ease-in-out;
            .bn-icon {
              margin-left: 15px;
              font-size: 18px;
            }
            &:hover {
              background-color: $clrc-dark-blue;
              color: $clr__white !important;
            }
          }
        }
        // ie 11 fix for "align-items: center" with a "min-height"
        &:after{
          content: '';
          font-size: 0;
          min-height: inherit;
        }
      }
      .bn-room-box__labels {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        z-index: 10;
        .bn-room-box__label {
          background-color: $clrc-dark-blue;
          display: block;
          overflow: hidden;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px 25px;
            position: relative;
            text-align: center;
          }
          .bn-icon {
            display: inline-block;
            font-size: 3.0em;
            margin-bottom: 10px;
          }
          .bn-room-box__amount-addition,
          .bn-room-box__max-persons {
            font-size: 1.6em;
          }
          .bn-room-box__amount-addition {
            margin-right: 5px;
          }
          .bn-room-box__amount {
            font-size: 2.5em;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      // remove margin top on first and second box
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
  }
  .bn-rooms-teaser__dots {
    @include slider--dots-v2($clrc-dark-blue,6);
    margin-top: 30px;
  }
  .bn-rooms-teaser__arrows {
    @include slider--arrows-v2($clrc-dark-blue,$clrc-light-blue);
    .bn-arrows__arrow {
      transform: none;
    }
  }
  &.bn-rooms-teaser--filtered {
    .bn-rooms-teaser__holder {
      .bn-room-box {
        display: none;
        &.bn-room-box--act {
          display: block;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// desktop small
@media #{$mq__desktop--small} {
  .bn-rooms-teaser {
    .bn-rooms-teaser__holder {
      .bn-room-box {
        .bn-room-box__content {
          min-height: 400px;
        }
      }
    }
  }
}

// laptop
@media #{$mq__laptop} {
  .bn-rooms-teaser {
    .bn-rooms-teaser__holder {
      .bn-room-box {
        padding-top: 50px;
        .bn-room-box__content {
          min-height: 350px;
        }
        .bn-room-box__labels {
          top: -50px;
          .bn-room-box__label {
            height: 100px;
            margin-left: 10px;
            min-width: 100px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-rooms-teaser {
    .bn-rooms-teaser__holder {
      display: block;
      .bn-room-box {
        margin-top: 40px !important;
        width: 100%;
        .bn-room-box__content {
          .bn-room-box__new {
            right: 0.5em;
            top: 0.5em;
            font-size: 1.6em;
          }
          > div {
            padding: 2em 4em;
          }
          .bn-room-box__description {
            font-size: 1.4em;
            margin-top: 10px;
          }
          .bn-room-box__stats {
            margin-top: 30px;
          }
          .bn-room-box__book {
            .bn-button--request {
              height: 40px;
              font-size: 12px;
              .bn-icon {
                font-size: 14px;
              }
            }
            .bn-button--book {
              height: 40px;
              font-size: 12px;
              .bn-icon {
                font-size: 14px;
              }
            }
          }
        }
        .bn-room-box__labels {
          top: 0;
          .bn-room-box__label {
            .bn-room-box__amount-addition,
            .bn-room-box__max-persons {
              font-size: 1.4em;
            }
            .bn-room-box__amount {
              font-size: 2.0em;
            }
          }
        }
        &:first-child {
          margin-top: 0 !important;
        }
      }
    }
    .bn-rooms-teaser__arrows {
      display: none;
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-rooms-teaser {
    .bn-rooms-teaser__tabs {
      padding-bottom: 30px;
      > div {
        display: block;
      }
    }
    .bn-rooms-teaser__holder {
      .bn-room-box {
        margin-top: 20px !important;
        > div {
          flex-direction: column !important;
        }
        .bn-room-box__content {
          min-height: 300px;
          margin-left: 0;
          margin-top: 10px;
          .bn-room-box__button {
            margin-top: 20px;
            .bn-button {
              > span {
                font-size: 11px;
              }
            }
          }
        }
        &:first-child {
          margin-top: 0 !important;
        }
        &:nth-child(2n) {
          > div {
            .bn-room-box__content {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-rooms-teaser {
    .bn-rooms-teaser__tabs {
      padding-bottom: 15px;
      > div {
        padding: 5px;
      }
    }
    .bn-rooms-teaser__holder {
      .bn-room-box {
        padding-top: 40px;
        .bn-room-box__content {
          min-height: 250px;
          .bn-room-box__stats {
            margin-top: 10px;
          }
          .bn-room-box__button {
            margin-top: 10px;
            .bn-button {
              min-width: 0;
              width: 100%;
            }
          }
        }
        .bn-room-box__labels {
          .bn-room-box__label {
            height: 80px;
            min-width: 80px;
            > div {
              padding: 10px 15px;
            }
            .bn-icon {
              font-size: 1.6em;
              margin-bottom: 5px;
            }
            .bn-room-box__amount-addition,
            .bn-room-box__max-persons {
              font-size: 1.2em;
            }
            .bn-room-box__amount {
              font-size: 1.6em;
            }
            &.bn-room-box__label--persons {
              display: none;
            }
          }
        }
      }
    }
  }
}
