/*  ==========================================================================
    POPUP
    ========================================================================== */

$module-clr__text--text: $clr__typo--primary;
$module-clr__text--accent: $clr__ci--primary;
$module-clr__text--subheadline: $clr__typo--primary;


.bn-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba($clr__black, 0.7);
  z-index: 2000;
  backdrop-filter: blur(5px);
  display: none;
  &.js-act {
    display: flex;
  }
  &__wrapper {
    background: $clr__white;
    padding: 50px;
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 20px;

    .bn-content {
      &__title {
        margin-bottom: 20px;
        @include typo--subheadline($module-clr__text--subheadline);
      }
      &__text {
        @include typo--rte($module-clr__text--text,$module-clr__text--accent);
        p {
          line-height: 1.8;
        }
      }
      &__buttons {
        display: flex;
        margin-top: 40px;
        justify-content: center;
        > div {
          &.dna-close {
            cursor: pointer;
            span {
              color: $clr__white;
              background: $clr__gray-button;
              &:hover {
                background: $clr__gray-button--hover;
              }
            }
          }
          &.dna-more {
            margin-right: 10px;
            a {
              color: $clr__white;
              background: rgba($clr__ci--primary, 0.7);
              &:hover {
                background: $clr__ci--primary;
              }
            }
          }
          > * {
            padding: 10px 20px;
            font-size: 2em;
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-popup {
    &__wrapper {
      padding: 40px 25px;
      .bn-content {
        &__text {
          p {
            font-size: 1.4em;
          }
        }
        &__buttons {
          > div {
            > * {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}