/*  ==========================================================================
    QUICKREQUEST
    ========================================================================== */

/*
 * MODULE
 */

.bn-quickrequest #bn_quickrequest_vertical {
  .bn-form-quickrequest {
    .bn-form__group {
      .bn-form__customlabel {
        color: $clr__typo--primary;
      }
    }
  }
  .bn-form-quickrequest-container {
    background-color: $clr__white !important;
  }
  .bn-form__select-wrapper:after {
    border-color: $clr__typo--primary !important;
  }
  select,
  textarea,
  input.has-value,
  input {
    border-bottom-color: $clr__typo--primary !important;
  }
  .bn-iconBtn {
    border-color: $clr__typo--primary !important;
  }
  .consent_container,
  input:not(.numInput),
  select,
  select~.bn-form__label,
  .bn-iconBtn,
  .bn-form__column--requiredFields,
  .bn-form__groupIcon,
  .bn-form__label,
  input.has-value~.bn-form__label,
  input.has-value,
  input:focus~.bn-form__label,
  input~.bn-form__label {
    color: $clr__typo--primary!important;
  }
  .bn-childrenContainer {
    background: $clr__white !important;
  }
  .bn-form__button {
    background-color: $clr__cta--primary !important;
    border: 0 !important;
    &:hover {
      color: $clr__white !important;
      background-color: $clr__cta--primary !important;
      opacity: 0.8;
    }
  }
  .bn-form__column--submit {
    max-width: 350px;
    margin-left: 0;
    margin-right: auto;
  }
  .bn-form-quickrequest .bn-form__grid .bn-form__block .bn-form__row .bn-form__column.bn-form__column--custom_comment textarea {
    color: $clr__typo--primary!important;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
