/*  ==========================================================================
    OFFER BUTTONS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__bestprice--primary: $clr__ci--primary;
$module-clr__bestprice--cta-primary: $clr__cta--primary;
$module-clr__bestprice--secondary: $clr__ci--secondary;
$module-clr__bestprice--white: $clr__white;
$module-clr__bestprice--grey: $clr__qr;

/*
 * MODULE
 */

.bn-offerbuttons {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5;
  &-wrapper {
    display: flex;
    flex-direction: row;
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity 0.5s;
    > span {
      font-size: 2.6em;
      text-transform: uppercase;
      line-height: 1;
    }
    > i {
      font-size: 1.5em;
      margin-left: 5px;
    }
    &--offers {
      padding: 25px 50px;
      background-color: $clrc-light-blue;
      color: $clr__white !important;
    }
    &--chalets {
      padding: 25px;
      background-color: $clr__white;
      color: $clrc-light-blue !important;
    }
    &:hover {
      opacity: 0.9;
    }
    &--image {
      &:hover {
        opacity: 1;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media #{$mq__tablet} {
  .bn-offerbuttons {
    display: block;
    left: auto;
    bottom: auto;
    right: 0;
    top: 8em;
    &__box {
      align-items: center;
      > span {
        font-size: 1.5em;
      }
      &--offers {
        padding: 20px 50px;
        flex-direction: row;
      }
      &--chalets,
      &--image {
        display: none;
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-offerbuttons {
    &__box {
      > span {
        font-size: 1.2em;
      }
      &--offers {
        padding: 20px 15px;
      }
    }
  }
}

@media #{$mq__phone} {
  .bn-offerbuttons {
    top: 65px;
  }
}