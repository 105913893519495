/*  ==========================================================================
    HEADER V1
    ========================================================================== */

@mixin header--v1() {
  height: $unit__hgt--header-v1;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  font-family: $typo__ff--primary;
  // controls
  .bn-controls {
    height: 100%;
    display: flex;
    > .bn-grid__row {
      position: relative;
      height: 100%;
      flex: 1;
      border-bottom: 1px solid rgba($clr__white, 0.25);
      padding: 0 4em 0 7em;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 125%;
        z-index: -1;
        transition: opacity 0.25s ease-in-out;
        background: linear-gradient(to bottom, rgba($clr__black, 0.5), transparent);
      }
    }
    .bn-controls__left {
      max-width: 280px;
      position: relative;
    }
    .bn-controls__right {
      .bn-controls__right___top {
        font-size: 1.4em;
        height: 50%;
        a {
          color: $clr__white;
          &:visited {
            color: $clr__white;
          }
          &:hover {
            color: $clrc-light-green;
          }
        }
        > .bn-grid__col {
          align-items: center;
          display: flex;
          justify-content: flex-end;
        }
      }
      .bn-controls__right___bottom {
        height: 50%;
        > .bn-grid__col {
          @include clearfix();
        }
      }
    }
    .bn-offerbutton {
      width: 30em;
      display: flex;
      flex-direction: column;
      &__link {
        background-color: $clrc-light-blue;
        color: $clr__white;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        transition: background-color 0.25s ease-in-out;
        &:nth-child(2) {
          background-color: $clrc-dark-blue;
        }
        &__wrap {
          display: flex;
          align-items: center;
          gap: 2em;
          > span {
            font-size: 1.8em;
            display: flex;
          }
          .bn-icon {
            &--mail {
              font-size: 2.6em;
              padding-bottom: 3px;
            }
            &--arrow-right {
              font-size: 1.4em;
            }
          }
        }
        &__icon {
          position: relative;
          height: 1.6em;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          > i {
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translate(50%, -50%);
            font-size: 1.3em;
            transition: all 0.25s ease-in-out;
          }
        }
        &__text {
          padding-right: 2em;
          line-height: 1;
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
          > span {
            font-size: 1em;
            text-transform: uppercase;
            font-weight: $typo__fw--bold;
          }
        }
        &:hover {
          background-color: $clrc-light-orange;
          .bn-offerbutton {
            &__link {
              &__icon {
                > i {
                  right: 0;
                  transform: translate(0, -50%);
                }
              }
              &__text {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: flex;
      flex-direction: row;
      width: 100%;
      &:before {
        background-color: $clr__ci--primary;
        bottom: -1px;
        content: '';
        display: block;
        height: calc(100% + 1px - #{$unit__hgt--header-v1});
        left: -1px;
        position: absolute;
        width: calc(100% + 2px);
      }
      .bn-main-logo__image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        z-index: $unit__zi--header-v1-main-logo-image;
        img {
          @include img--rendering-crisp-edges();
          max-width: 27em;
          max-height: 5em;
          width: 100%;
          display: block;
        }
        &.bn-main-logo__image--big {
          align-items: flex-start;
          justify-content: flex-end;
          background-color: $clr__white;
          margin-bottom: 1.5em;
          img {
            width: 27em;
          }
        }
        &.bn-main-logo__image--small {
          align-items: flex-start;
          display: none;
          height: 100%;
          img {
            height: 80px;
          }
        }
        &.bn-main-logo__image--big--novista,
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // top references
  .bn-top-references {
    display: flex;
    align-items: center;
    &.bn-top-references--above {
      margin-right: 25px;
      .bn-top-references__item {
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.25s ease-in-out;
        .bn-icon {
          font-size: 1.4em;
        }
        &:hover {
          border-color: $clr__ci--primary;
        }
        &:first-child {
          margin-left: 0;
        }
        &.bn-top-references__item--trigger {
          display: none;
        }
        &.bn-top-references__item--static {
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          &:hover {
            color: $clrc-light-green !important;
          }
          &.bn-top-references__item--static--mobile {
            display: none;
          }
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--above {
      margin-right: 10px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.25s ease-in-out;
        > i {
          font-size: 14px;
          margin-right: 10px;
        }
        &:hover {
          color: $clrc-light-green !important;
        }
      }
      .break {
        display: none;
      }
      .bn-icon {
        font-size: 1.4em;
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        @include clearfix();
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '-';
            user-select: none;
            pointer-events: none;
          }
          a {
            padding: 0 5px;
            font-weight: $typo__fw--bold;
            cursor: pointer;
            transition: color 0.25s ease-in-out;
            &:hover {
              color: $clrc-light-green !important;
            }
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clrc-light-green !important;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    float: right;
    height: 100%;
    padding-left: 150px;
    width: 100%;
    .bn-main-menu__lvl--0 {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      gap: 4em;
      padding-left: 0;
      list-style-type: none;
      .bn-main-menu__item {
        > .bn-main-menu__arrow {
          display: none;
        }
      }
      > .bn-main-menu__item {
        height: 100%;
        position: relative;
        text-align: center;
        > .bn-main-menu__link {
          position: relative;
          align-items: center;
          color: $clr__typo--primary;
          display: flex;
          flex: 1;
          height: 100%;
          padding: 0 10px;
          font-weight: $typo__fw--semibold;
          white-space: nowrap;
          transition: color 0.25s ease-in-out;
          .bn-main-menu__hover-bar {
            background-color: $clrc-light-green;
            color: $clr__white;
            max-width: 7em;
            width: 100%;
            height: 1.3em;
            position: absolute;
            bottom: 0;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.25s ease-in-out;
            > span {
              font-size: 1em;
              text-transform: uppercase;
              font-weight: $typo__fw--bold;
            }
          }
          .bn-main-menu__new-tag {
            display: none;
          }
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 1.6em;
              letter-spacing: 1.8px;
            }
            .bn-main-menu__subtitle {
              font-size: 1.4em;
            }
          }
          .bn-sub-menu-trigger {
            display: none;
          }
          &:visited {
            color: $clr__typo--primary;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
        &:hover {
          > .bn-main-menu__link {
            .bn-main-menu__hover-bar {
              opacity: 1;
            }
          }
        }
        &--28 {
          > .bn-main-menu__link {
            .bn-main-menu__hover-bar {
              opacity: 1;
            }
          }
        }
        .bn-main-menu__sub-menu {
          color: $clr__typo--secondary;
          display: none;
          left: 50%;
          position: absolute;
          text-align: center;
          top: 100%;
          transform: translateX(-50%);
          width: 280px;
          .bn-main-menu__lvl-wrap {
            .bn-main-menu__lvl {
              list-style-type: none;
              padding-left: 0;
              .bn-main-menu__icon {
                margin-bottom: 1.5em;
                display: flex;
                flex-direction: column;
                gap: 2.5em;
                > i {
                  color: $clrc-light-green;
                  font-size: 7em;
                }
                .bn-icon {
                  &--seaside-logo {
                    font-size: 4em;
                    color: $clr__white;
                  }
                }
              }
              .bn-main-menu__item {
                padding: 5px 0;
                .bn-main-menu__link {
                  color: $clr__white;
                  font-size: 1.4em;
                  letter-spacing: 0.9px;
                  text-transform: uppercase;
                  transition: text-shadow 0.25s ease-in-out, color 0.25s ease-in-out;
                  &:visited {
                    color: $clr__white;
                  }
                  &:hover {
                    color: $clrc-light-green;
                    text-shadow: 1px 0 $clrc-light-green;
                  }
                }
                &.bn-main-menu__item--act,
                &.bn-main-menu__item--cur {
                  > .bn-main-menu__link {
                    color: $clrc-light-green !important;
                  }
                }
              }
              &.bn-main-menu__lvl--1 {
                background-color: rgba($clrc-river-bed, 0.9);
                padding: 20px 20px 25px 20px;
                border-top: 1px solid rgba($clr__white, 0.25);
              }
              &.bn-main-menu__lvl--2 {
                padding-top: 5px;
                .bn-main-menu__item {
                  padding-left: 40px;
                  position: relative;
                  &:before {
                    content: '-';
                    display: block;
                    font-size: 2.0em;
                    left: 20px;
                    position: absolute;
                    text-align: center;
                    top: 5px;
                    width: 20px;
                  }
                  &.bn-main-menu__item--last {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
        &.bn-main-menu__item--last {
          .bn-main-menu__link {
            padding-right: 0;
          }
        }
        &:hover {
          .bn-main-menu__sub-menu {
            display: block;
          }
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__ci--primary;
          }
        }
      }
    }
  }
  // minimized
  &.bn-header--minimized {
    height: $unit__hgt--header-v1-minimized;
    // controls
    .bn-controls {
      > .bn-grid__row {
        border-bottom-color: transparent;
        &:before {
          opacity: 0;
        }
      }
    }
    .bn-top-references {
      &.bn-top-references--above {
        .bn-top-references__item {
          &.bn-top-references__item--static {
            display: flex;
            //display: inline-flex;
          }
          &.bn-top-references__item--static--mobile {
            display: none;
          }
        }
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        height: 100%;
        &:before {
          display: none;
        }
        .bn-main-logo__image {
          &.bn-main-logo__image--big {
            display: none;
          }
          &.bn-main-logo__image--big--novista {
            display: none;
          }
          &.bn-main-logo__image--small {
            display: flex;
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // full screen on
  @media #{$mq__full-screen--on} {
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                &.bn-main-menu__lvl--1 {
                  max-height: calc(100vh - #{$unit__hgt--header-v1} - #{$unit__hgt--header-v1-sub-menu-arrow});
                  overflow-y: auto;
                  border-top: 1px solid rgba($clr__white, 0.25);
                  &::-webkit-scrollbar {
                    width: 5px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: $clr__ci--secondary;
                  }
                  &::-webkit-scrollbar-thumb:hover {
                    background: rgba($clr__ci--secondary, 0.9);
                  }
                }
              }
            }
          }
        }
      }
    }
    // minimized
    &.bn-header--minimized {
      // main menu
      .bn-main-menu {
        .bn-main-menu__lvl--0 {
          > .bn-main-menu__item {
            .bn-main-menu__sub-menu {
              .bn-main-menu__lvl-wrap {
                .bn-main-menu__lvl {
                  &.bn-main-menu__lvl--1 {
                    max-height: calc(100vh - #{$unit__hgt--header-v1-minimized} - #{$unit__hgt--header-v1-sub-menu-arrow});
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // main menu
    .bn-main-menu {
      padding-left: 50px;
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            width: 250px;
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                &.bn-main-menu__lvl--2 {
                  .bn-main-menu__item {
                    padding-left: 20px;
                    &:before {
                      left: 0;
                    }
                  }
                }
              }
            }
          }
          &.bn-main-menu__item--first {
            .bn-main-menu__sub-menu {
              left: 0;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
  // 1800px
  @media #{$mq__max-w--1800} {
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        gap: 2em;
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            > .bn-main-menu__titles {
              .bn-main-menu__title {
                font-size: 1.5em;
              }
            }
          }
        }
      }
    }
    .bn-controls {
      .bn-offerbutton {
        width: 25em;
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    .bn-main-menu {

      .bn-main-menu__lvl--0 {
        gap: 2em;
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    // controls
    .bn-controls {
      .bn-controls__left {
       max-width: 160px;
      }
      >.bn-grid__row {
        padding: 0 2em 0 4em;
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            max-width: 160px;
          }
          &.bn-main-logo__image--big {
          }
          &.bn-main-logo__image--small {
            img {
              height: 40px;
            }
          }
        }
      }
    }
    // main menu
    .bn-main-menu {
      padding-left: 25px;
      .bn-main-menu__lvl--0 {
        gap: 2em;
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 0 5px;
            > .bn-main-menu__titles {
              .bn-main-menu__title {
                font-size: 1.3em;
              }
            }
          }
        }
      }
    }
  }
}
