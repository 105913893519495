/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-request {
  .bn-request__holder {
    //background-color: rgba($module-clr__request--bg,0.1); Optional
    .bn_request_variation__vertical {
      @include request--v1();
    }
  }
  &__optionals {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 4em;
    grid-row-gap: 2em;

    &-title {
      margin-top: 4em;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      line-height: 1;
      margin-bottom: 8px;
      color: $clr__typo--primary;
    }

    &-box {
      display: flex;
      align-items: center;
      border: 1px solid $clrc-dark-blue;
      color: $clrc-dark-blue;
      background-color: $clr__white;
      border-radius: 4px;
      padding: .8em 1.5em;
      cursor: pointer;

      &.js-active {
        background-color: $clr__white;

        .bn-request__optionals-radiobox {
          border-color: $clrc-dark-blue;

          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            border-radius: 50%;
            background-color: $clrc-dark-blue;
          }
        }

        .bn-request__optionals-checkbox {
          border-color: $clrc-dark-blue;
          background-color: $clr__white;

          i {
            color: $clrc-dark-blue;
            opacity: 1;
            font-size: 15px;
          }
        }
      }
    }

    &-radiobox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6em;
      height: 1.6em;
      border: 1px solid $clrc-dark-blue;
      border-radius: 50%;
      margin-right: 1.5em;
      position: relative;
    }

    &-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6em;
      height: 1.6em;
      border: 1px solid $clrc-dark-blue;
      border-radius: 3px;
      margin-right: 1.5em;

      i {
        opacity: 0;
      }
    }

    &-label {
      font-size: 1.5em;
    }
  }
}

//Disable Offerpreview on Offerpoup & Offerdetail
.popupForm,
.detailForm{
  .ractive--offer-lastminute-Template{
    display: none;
  }
}

//Set White as Standard Background
.bn-form-request .bn-form__grid .bn-form__block{
  background-color: #fff;
}

//Flatpicker Index
body .bn_request_flatpickr{
  z-index: 200 !important;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
