/*  ==========================================================================
    FOOTER V4
    ========================================================================== */

@mixin footer--v4() {
  padding-bottom: calc(#{$unit__hgt--footer-fixed-bar} + 90px);
  padding-top: 20px;
  background-color: $clr__white;
  .dna-module {
    &__row {
      &--1 {
        display: flex;
        margin-bottom: 10px;
      }
      &--3 {
        margin-top: 10px;
      }
      &--4 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    &__col {
      width: 50%;
      display: flex;
      align-items: flex-end;
      &--info {
        padding-right: 60px;
        .bn-main-logo {
          margin-right: 80px;
          &__image {
            &--big,
            &--big--novista,
            &--mobile {
              display: none;
            }
            img {
              width: 100%;
              max-width: 215px;
            }
          }
        }
      }
      &--shortlinks {
        .dna-shortlinks {
          display: flex;
          flex-direction: column;
          &__item {
            color: $clr__black;
            padding-top: 0.5em;
            > span {
              font-size: 14px;
              text-transform: uppercase;
            }
            > i {
              font-size: 12px;
              margin-right: 5px;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      &--tripadvisor {
        justify-content: flex-end;
        align-items: center;
        gap: 3em;
        .bn-tripadvisor,
        .bn-holidaycheck {
          transition: opacity 0.25s ease-in-out;
          > i {
            color: $clr__typo--primary;
            font-size: 90px;
          }
          &:hover {
            opacity: 0.5;
          }
        }
        .bn-holidaycheck {
          > i {
            color: $clr__typo--primary;
            font-size: 25px;
          }
        }
        .bn-roadbike-holidays {
          display: block;
          max-width: 100px;
          transition: opacity 0.25s ease-in-out;
          img {
            width: 100%;
            height: auto;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  // address
  .bn-address {
    &.bn-address--v1 {
      font-size: 1.4em;
      font-style: normal;
      line-height: 1.6;
      text-transform: uppercase;
      white-space: nowrap;
      > a {
        color: $clr__typo--primary;
        &:hover {
          color: $clrc-light-orange;
        }
      }
      .bn-address__item {
        &.bn-address__company {
          font-weight: $typo__fw--bold;
        }
        &.bn-address__item--de,
        &.bn-address__item--en,
        &.bn-address__item--it {
          display: none;
        }
      }
      &.bn-address--de {
        .bn-address__item {
          &.bn-address__item--de {
            display: inline;
          }
        }
      }
      &.bn-address--en {
        .bn-address__item {
          &.bn-address__item--en {
            display: inline;
          }
        }
      }
      &.bn-address--it {
        .bn-address__item {
          &.bn-address__item--it {
            display: inline;
          }
        }
      }
    }
  }
  // references
  .bn-references {
    font-size: 2em;
    a {
      color: $clrc-light-blue;
      &:visited {
        color: $clrc-light-blue;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .bn-references__item {
      display: inline-block;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // legal menu
  .bn-legal-menu {
    &.bn-legal-menu--v1 {
      font-size: 1.2em;
      font-weight: $typo__fw--light;
      text-transform: uppercase;
      a {
        color: $clr__typo--primary;
        &:hover {
          color: $clrc-light-orange;
        }
      }
      .bn-break {
        display: none;
      }
      .bn-legal-menu__item {
        &.bn-legal-menu__item--cur {
          color: $clrc-light-orange;
        }
      }
    }
  }
  // powered by
  .bn-powered-by {
    color: $clr__black;
    display: inline-block;
    width: 90px;
    img {
      width: 100%;
      height: auto;
    }
    > i {
      font-size: 16px;
    }
    &:visited {
      color: $clr__black;
    }
    &:hover {
      color: $clrc-light-orange;
    }
  }
  // partners
  .bn-partners {
    padding-top: 40px;
    display: flex;
    align-items: center;
    gap: 40px;
    .bn-partners__item {
      display: inline-block;
      margin-top: 20px;
      &:first-child {
        padding-left: 0;
      }
      img {
        @include img--rendering-crisp-edges();
        max-width: 250px;
        max-height: 100px;
        width: 100%;
        height: auto;
      }
    }
  }
  // useful pages
  .bn-useful-sites {
    font-size: 1.2em;
    font-weight: $typo__fw--light;
    a {
      color: $clr__typo--primary;
      .bn-useful-sites__comma {
        color: $clr__typo--primary;
      }
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clrc-light-orange;
      }
    }
    .bn-useful-sites__title {
      display: inline-block;
      font-weight: $typo__fw--bold;
      margin-right: 5px;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          max-height: 90px;
        }
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          max-height: 80px;
        }
      }
    }
  }

  // laptop
  @media #{$mq__laptop} {
    .bn-powered-by-wrap {
      margin-top: 2em;
    }
  }

  // tablet
  @media #{$mq__tablet} {
    .dna-module {
      &__col {
        &--info {
          justify-content: space-between;
          width: 100%;
          padding-right: 0;
          margin-bottom: 40px;
        }
        &--address {
          width: 100%;
        }
        &--tripadvisor {
          gap: 2em;
          .bn-tripadvisor,
          .bn-holidaycheck {
            > i {
              font-size: 65px;
            }
          }
          .bn-holidaycheck {
            > i {
              font-size: 20px;
            }
          }
          .bn-roadbike-holidays {
            max-width: 85px;
          }
        }
      }
    }
    .bn-powered-by-wrap {
      margin-top: 10px;
      width: 100%;
      text-align: right;
      .bn-powered-by {
        > i {
          font-size: 14px;
        }
      }
    }
    // references
    .bn-references {
      .bn-references__item {

      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        .bn-powered-by {
        }
      }
    }
    // partners
    .bn-partners {
      text-align: center;
      .bn-partners__item {
        &:first-child {
          padding-left: 40px;
        }
        img {
          max-height: 70px;
        }
      }
    }

  }
  // phablet
  @media #{$mq__phablet} {
    .dna-module {
      &__col {
        &--info {
          flex-wrap: wrap;
          > * {
            width: 100%;
          }
        }
        &--shortlinks {
          width: 100%;
        }
        .bn-main-logo {
          text-align: center;
          margin-right: 0;
          margin-bottom: 40px;
        }
        &--tripadvisor {
          justify-content: flex-start;
          width: 100%;
          margin-top: 2em;
        }
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        .bn-pipe {
          display: none;
        }
        .bn-break {
          display: inline;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    // weather teaser
    .bn-weather-teaser {
      &.bn-weather-teaser--small {
        font-size: 1.4em;
      }
    }
    // partners
    .bn-partners {
      gap: 20px;
      .bn-partners__item {
        img {
          max-height: 60px;
          max-width: 200px;
        }
      }
    }
  }
}
