/*  ==========================================================================
    OFFERS
    ========================================================================== */

// filter
@mixin offers--filter($default,$accent) {
  margin-bottom: 40px;
  .bn-offers-filter__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-offers-filter__period,
    .bn-offers-filter__categories {
      color: $default;
      font-size: 1.6em;
      text-transform: uppercase;
      .bn-offers-filter__title {
        font-weight: $typo__fw--bold;
        margin-bottom: 10px;
        white-space: nowrap;
      }
      .bn-offers-filter__options {
        .bn-offers-filter__option {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          position: relative;
          label {
            white-space: nowrap;
          }
          input {
            background-color: $clr__white;
            border: 1px solid $default;
            border-radius: 20px;
            color: $accent;
            cursor: pointer;
            &:focus {
              border-color: $accent;
            }
          }
          label,
          input {
            line-height: 30px;
          }
          .bn-offers-filter__option-overlay {
            cursor: pointer;
            display: none;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }
      }
    }
    .bn-offers-filter__period {
      width: calc(25% - 40px);
      .bn-offers-filter__options {
        .bn-offers-filter__option {
          label {
            width: calc(25% - 5px);
          }
          input {
            height: 30px;
            padding: 0 10px;
            width: calc(75% - 5px);
          }
          .bn-offers-filter__option-overlay {
            display: block;
            width: calc(75% - 5px);
          }
          &.bn-offers-filter__option--to {
            margin-top: 10px;
          }
        }
      }
      + .bn-offers-filter__categories {
        width: calc(75% - 40px);
      }
    }
    .bn-offers-filter__categories {
      width: 100%;
      .bn-offers-filter__options {
        display: flex;
        flex-wrap: wrap;
        .bn-offers-filter__option {
          margin-top: 10px;
          width: 33.33%;
          input {
            text-align: center;
            width: 20px;
          }
          label {
            width: calc(100% - 25px);
          }
          // remove margin top on first 3 options
          &:nth-child(-n+3) {
            margin-top: 0;
          }
        }
      }
    }
  }
  .bn-offers-filter__button {
    margin-top: 20px;
    .bn-offers-filter__submit {
      @include button--default();
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    .bn-offers-filter__controls {
      display: block;
      .bn-offers-filter__period,
      .bn-offers-filter__categories {
        font-size: 1.4em;
        width: 100% !important;
        .bn-offers-filter__options {
          .bn-offers-filter__option {
            .bn-offers-filter__option-overlay {
              display: none !important;
            }
          }
        }
      }
      .bn-offers-filter__categories {
        margin-top: 20px;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    margin-bottom: 20px;
    .bn-offers-filter__controls {
      .bn-offers-filter__period,
      .bn-offers-filter__categories {
        font-size: 1.2em;
      }
      .bn-offers-filter__categories {
        .bn-offers-filter__options {
          .bn-offers-filter__option {
            margin-top: 10px;
            width: 50%;
            // remove margin top on first 2 options
            &:nth-child(-n+3) {
              margin-top: 10px;
            }
            &:nth-child(-n+2) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-offers-filter__controls {
      .bn-offers-filter__period,
      .bn-offers-filter__categories {
        font-size: 1.4em;
      }
      .bn-offers-filter__categories {
        .bn-offers-filter__options {
          display: block;
          .bn-offers-filter__option {
            margin-top: 10px;
            width: 100%;
            // remove margin top on first option
            &:nth-child(-n+2) {
              margin-top: 10px;
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

// detail
@mixin offers--detail($accent,$bg-price,$description,$headline,$suheadline,$typo-price) {
  .bn-offer-box {
    .bn-offer-box__toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 125px;
      display: none;
      z-index: 10;
      &__middle {
        background-color: $clrc-light-orange;
        color: $clr__white;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 35px;
      }
      &__infos  {
        width: 100%;
        font-size: 21px;
        .name {
          font-weight: $typo__fw--semibold;
          letter-spacing: 2px;
        }
      }
      &__date {
        text-transform: uppercase;
        font-size: 16px;
      }
      &__switcher {
        display: flex;
        font-size: 1.8em;
        align-items: center;
        color: white;
        margin-top: 1em;
        &-main {
          text-transform: uppercase;
          font-weight: $typo__fw--semibold;
        }
        &-button {
          font-size: 1.25em;
          color: white;
          transition: transform 0.3s;
          &.bn-icon--arrow-bordered-left {
            margin-right: 10px;
          }
          &.bn-icon--arrow-bordered-right {
            margin-left: 10px;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &__right {
        background-color: $clrc-light-green;
        color: $clr__white;
        padding: 0 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 21px;
        height: 100%;
        text-transform: uppercase;
        transition: background-color 0.5s;
        .bn-offer-box__price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
          > div {
            width: 100%;
            white-space: nowrap;
          }
        }
        .bn-prenota {
          display: flex;
          align-items: center;
          > span {
            white-space: nowrap;
            margin-right: 15px;
          }
          > i  {
            font-size: 16px;
          }
        }
        &:hover {
          background-color: rgba($clrc-light-green, 0.8);
        }
      }
    }

    .bn-offer-box__wrapper {
      display: flex;
      margin-top: 35px;
    }
    .bn-button.bn-toggleimage{
      @include button--default();
      height: 40px;
      line-height: 37px;
      margin-bottom: 10px;
      border-radius: 25px;
    }
    .bn-offer-box__images {
      width: 50%;
      overflow: hidden;
      position: relative;
      &.bn-hidden {
        display: none;
      }
      .bn-offer-box__slider-container {
        overflow: hidden;
        position: relative;
        height: 100%;

        .bn-offer-box__slider {
          width: 100%;
          height: 100%;

          .slick-list,
          .slick-track,
          .slick-slide > div {
            height: 100%;
          }

          .bn-offer-box__image {
            overflow: hidden;
            height: 100%;
            max-height: 600px;

            img {
              @include img--responsive();
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .bn-offer-box__arrows {
          @include slider--arrows($clr__white);
          left: 0;
          padding: 0 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 5;
        }
        .bn-offer-box__dots {
          @include slider--dots($clr__white,6);
          bottom: 20px;
          left: 20px;
          position: absolute;
          width: 100%;
          z-index: 5;
        }
      }
      .bn-offer-box__price {
        background-color: $clrc-dark-blue;
        color: $clr__typo--secondary;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 150px;
        z-index: 5;
        > div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 10px;
          position: relative;
          text-align: center;
        }
        .bn-offer-box__amount-addition {
          font-size: 1.6em;
        }
        .bn-offer-box__amount {
          font-size: 2.5em;
          &.bn-offer-box__amount--bigger {
            font-size: 3.5em;
          }
        }
      }
    }
    .bn-offer-box__content {
      width: 50%;
      margin-right: 25px;
      background-color: $clrc-light-background;
      padding: 30px 90px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .bn-offer-box__seasondatas,
      .bn-offer-box__nights {
        font-size: 2.0em;
      }
      .bn-offer-box__seasondatas {
        margin-top: 20px;
      }
      .bn-offer-box__nights {
        margin-top: 10px;
      }
      .bn-offer-box__title {
        @include typo--headline($headline);
        margin-top: 10px;
      }
      .bn-offer-box__description {
        @include typo--rte($description,$accent);
        font-size: $typo__fs--text;
        margin-top: 20px;
        * {
          font-size: 1.0em !important;
        }
      }
      .bn-offer-box__rates,
      .bn-offer-box__inquiry,
      .bn-offer-box__buttons {
        margin-top: 40px;
      }
      .bn-offer-box__rates {
        @include typo--rte($description,$accent);
        .bn-table-wrap {
          .bn-table {
            tr {
              td {
                width: 25%;
                &:first-child {
                  width: 75%;
                }
              }
            }
          }
        }
      }
      .bn-offer-box__inquiry {
        .bn_request_variation__vertical {
          @include request--v1();
        }
      }
      .bn-offer-box__buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        > div {
          justify-content: space-between;
          &:nth-child(3){
            display:none;
          }
          .bn-button {
            &.bn-button--back {
              color: $accent;
              font-size: 1.4em;
              &:visited {
                color: $accent;
              }
              &:hover {
                color: rgba($accent, 0.5);
              }
              .bn-icon {
                margin-right: 5px;
              }
            }
            &.bn-button--inquire {
              @include button--cta();
              background-color: $clrc-light-blue;
              border-color: $clrc-light-blue;
              height: 40px;
              font-weight: $typo__fw--regular;
              &:hover {
                background-color: transparent;
                border-color: $clrc-light-blue !important;
                color: $clrc-light-blue !important;
              }
            }
            &.bn-button--book {
              @include button--cta();
            }
          }
        }
      }
    }

    .bn-offer-box__back {
      margin-bottom: 5em;
      margin-top: -6em;
      > a {
        text-transform: uppercase;
        font-size: 1.4em;
        color: $clrc-light-orange;
        font-weight: $typo__fw--semibold;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .bn-offer-box__name {
      @include typo--headline($headline);
      text-align: left;
      text-transform: initial;
    }

    .bn-offer-box__seasondatas {
      font-size: 1.6em;
      text-transform: uppercase;
      color: $clrc-light-blue;
      margin-top: 2.5em;
    }

    .bn-offer-box__subtitle {
      @include typo--rte($clrc-dark-blue, $clrc-light-blue);
      margin-top: 1em;
      max-width: 900px;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // laptop
  @media #{$mq__laptop} {
    .bn-offer-box {
      .bn-offer-box__toolbar {
        height: 120px;
      }
    }
  }

  // tablet
  @media #{$mq__tablet} {
    .bn-offer-box {
      .bn-offer-box__toolbar {
        height: 100px;
        &__left {
          display: none;
        }
        &__infos  {
          font-size: 15px;
        }
        &__switcher {
          font-size: 1.5em;
        }
        &__middle {
          padding: 0 20px;
        }
        &__right {
          padding: 0 20px;
          font-size: 15px;
        }
      }

      .bn-offer-box__wrapper {
        flex-direction: column-reverse;
      }

      .bn-offer-box__content {
        width: 100%;
        margin-right: 0;
        padding: 40px 15px;
      }

      .bn-offer-box__images {
        margin-bottom: 2em;
        width: 100%;
        .bn-offer-box__price {
          min-width: 125px;
          right: 20px;
          top: 20px;
          .bn-offer-box__amount {
            font-size: 2.0em;
            &.bn-offer-box__amount--bigger {
              font-size: 4em;
            }
          }
        }
      }
      .bn-offer-box__content {
        .bn-offer-box__buttons {
          > div {
            padding-left: 20px;
          }
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-offer-box {
      .bn-offer-box__toolbar {
        height: 100px;
        &__left {
          display: none;
        }
        &__infos  {
          font-size: 13px;
        }
        &__switcher {
          font-size: 1.3em;
        }
        &__middle {
          padding: 0 15px;
        }
        &__date {
          font-size: 13px;
        }
        &__right {
          padding: 0 15px;
          font-size: 13px;
        }
      }

      .bn-offer-box__images {
        .bn-offer-box__price {
          height: auto;
          min-width: 0;
          position: static;
          right: auto;
          top: auto;
          > div {
            display: block;
            height: auto;
          }
          .bn-offer-box__amount-addition,
          .bn-offer-box__amount {
            display: inline-block;
          }
          .bn-offer-box__amount-addition {
            margin: 0 5px;
          }
        }
      }
      .bn-offer-box__content {
        .bn-offer-box__seasondatas,
        .bn-offer-box__nights {
          font-size: 1.8em;
        }
        .bn-offer-box__buttons {
          flex-direction: column;
          > div {
            margin-bottom: 10px;
            max-width: 100%;
            padding-left: 0;
            &:first-child {
              margin-bottom: 0;
              order: 3;
            }
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-offer-box {
      .bn-offer-box__images {
        bn-offer-box__slider-container {
          .bn-offer-box__arrows {
            display: none;
          }
          .bn-offer-box__dots {
            bottom: 10px;
            left: 10px;
          }
        }
      }
    }
  }
  // 300px max width
  @media #{$mq__max-w--300} {
    .bn-offer-box {
      .bn-offer-box__images {
        .bn-offer-box__slider-container {
          .bn-offer-box__dots {
            display: none;
          }
        }
      }
    }
  }
}
