/*  ==========================================================================
    CONTROL BAR
    ========================================================================== */

@mixin mobile-header--control-bar() {
  color: $clr__white;
  cursor: pointer;
  display: none;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $unit__zi--mobile-header-control-bar;
  a {
    color: $clr__white;
    &:visited {
      color: $clr__white;
    }
    &:hover {
      color: $clr__white;
    }
  }
  .bn-control-bar__top,
  .bn-control-bar__center,
  .bn-control-bar__bottom {
    background-color: $clrc-river-bed;
    cursor: auto;
    display: flex;
    margin: 0;
    width: $unit__wdt--mobile-header-control-bar;
  }
  .bn-control-bar__top,
  .bn-control-bar__bottom {
    flex: none;
  }
  .bn-control-bar__top {
    padding: 0 2.5em;
    height: $unit__hgt--mobile-header-v1;
    > .bn-grid__col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .bn-control-bar__center {
    @include scrollbar($clr__scrollbar,5px);
    flex: 1;
    overflow-y: auto;
    background-color: $clrc-dark-blue;
    > .bn-grid__col {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 150px;
      .bn-main-menu {
        min-height: auto;
        background-color: transparent;
      }
      .bn-top-window-links {
        background: transparent;
        padding: 20px;
      }
    }
  }
  .bn-control-bar__bottom {
    > .bn-grid__col {
      @include clearfix();
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      display: inline-block;
      float: left;
      ul {
        @include clearfix();
        font-size: 1.6em;
        list-style-type: none;
        margin-left: -5px;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__white;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // close
  .bn-close {
    color: $clr__white;
    cursor: pointer;
    display: inline-block;
    float: right;
    .bn-icon {
      font-size: 2em;
    }
  }
  // main menu
  .bn-main-menu {
    min-height: 100%;
    padding: 15px 0;
    .bn-main-menu__lvl--0 {
      list-style-type: none;
      padding: 0 25px;
      margin-left: 15px;
      .bn-main-menu__item {
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clrc-light-orange;
          }
        }
      }
      > .bn-main-menu__item {
        margin-bottom: 15px;
        > .bn-main-menu__link {
          display: block;
          padding: 10px 50px 10px 0;
          position: relative;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 1.6em;
              letter-spacing: 1.8px;
              font-weight: $typo__fw--semibold;
            }
            .bn-main-menu__subtitle {
              font-size: 1.3em;
            }
          }
          .bn-sub-menu-trigger {
            color: $clrc-light-orange;
            cursor: pointer;
            font-size: 1.5em;
            height: calc(100% - 8px);
            line-height: 30px;
            position: absolute;
            right: 0;
            text-align: right;
            top: 50%;
            width: 40px;
            z-index: 5;
            display: none;
            padding-left: 15px;
            transform: translateY(-50%);
            .bn-icon {
              display: inline-block;
              transform: rotate(90deg);
              transition: transform 0.5s;
            }
            &.bn-sub-menu-trigger--act {
              .bn-icon {
                transform: rotate(270deg);
              }
            }
          }
          &:before {
            content: '';
            display: block;
            background-color: $clrc-light-green;
            height: 1px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: width 0.5s;
          }
          &--open {
            &:before {
              width: 3em;
            }
          }
        }
        .bn-main-menu__sub-menu {
          display: none;
          > .bn-main-menu__arrow {
            display: none;
          }
          .bn-main-menu__lvl-wrap {
            .bn-main-menu__lvl {
              list-style-type: none;
              padding-left: 0;
              .bn-main-menu__icon {
                display: none;
              }
              .bn-main-menu__item {
                .bn-main-menu__link {
                  font-size: 1.4em;
                  display: block;
                  padding: 5px 0;
                  text-transform: uppercase;
                  letter-spacing: 1.6px;
                }
              }
            }
            &.bn-main-menu__lvl-wrap--1 {
              padding: 5px 0;
              .bn-main-menu__item {
                position: relative;
              }
            }
            &.bn-main-menu__lvl-wrap--2 {
              padding: 5px 0;
              .bn-main-menu__item {
                position: relative;
                .bn-main-menu__link {
                  padding-left: 30px;
                }
              }
            }
          }
          &.bn-main-menu__sub-menu--open {
            display: block;
            position: relative;
            &:before {
              width: 2.5em;
            }
          }
        }
        &.bn-main-menu__item--last {
          .bn-main-menu__sub-menu {
            border-bottom: 0;
          }
        }
      }
      > .bn-main-menu__item.bn-main-menu__item--has-children {
        .bn-sub-menu-trigger {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      .bn-main-menu__hover-bar {
        display: none;
      }
      .bn-main-menu__titles {
        display: flex;
        align-items: center;
        gap: 1em;
        .bn-main-menu__new-tag {
          font-size: 1em;
          text-transform: uppercase;
          color: $clr__white;
          background-color: $clrc-light-green;
          font-weight: $typo__fw--bold;
          padding: 0.3em 1em;
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--aside {
      float: left;
      font-size: 1.6em;
      padding-right: 10px;
      width: 50%;
      display: none;
    }
  }
  // call to action
  .bn-call-to-action {
    &.bn-call-to-action--aside {
      padding: 20px;
      width: 100%;
      text-align: center;
      padding-top: 0;
      .bn-button {
        @include button--cta();
        border-radius: 0;
        border: 0;
        background-color: $clrc-light-blue !important;
        min-width: 0;
        width: 100%;
      }
    }
  }
  &.bn-control-bar--open {
    display: flex;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    // tel
    .bn-tel {
      &.bn-tel--aside {
        float: none;
        padding-right: 0;
        width: 100%;
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-control-bar__top {
      height: 65px;
    }
    // lang menu
    .bn-lang-menu {
      &.bn-lang-menu--aside {
        ul {
          font-size: 1.4em;
        }
      }
    }
    // main menu
    .bn-main-menu {
      padding: 15px 0;
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 5px 60px 5px 0;
            > .bn-main-menu__titles {
              .bn-main-menu__title {
                font-size: 1.8em;
              }
              .bn-main-menu__subtitle {
                font-size: 1.4em;
              }
            }
            .bn-sub-menu-trigger {
              right: 10px;
            }
          }
          .bn-main-menu__sub-menu {
            padding: 0 10px;
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                .bn-main-menu__item {
                  .bn-main-menu__link {
                    font-size: 1.4em;
                  }
                }
              }
              &.bn-main-menu__lvl-wrap--1,
              &.bn-main-menu__lvl-wrap--2 {
                .bn-main-menu__item {
                  &:before {
                    font-size: 1.4em;
                  }
                }
              }
            }
          }
        }
      }
    }
    // tel
    .bn-tel {
      &.bn-tel--aside {
        font-size: 1.4em;
      }
    }
    // call to action
    .bn-call-to-action {
      &.bn-call-to-action--aside {
        margin-top: 10px;
      }
    }
  }
}
