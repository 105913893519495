/*  ==========================================================================
    ROOMS
    ========================================================================== */

// detail
@mixin rooms--detail($accent,$bg-price,$description,$headline,$suheadline,$typo-price,$bg-visuals,$typo-visuals) {
  .bn-room-box {
    .bn-room-box__toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 125px;
      display: none;
      z-index: 10;
      &__middle {
        background-color: $clrc-dark-blue;
        color: $clr__white;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 35px;
      }
      &__infos  {
        width: 100%;
        font-size: 21px;
        .name {
          text-transform: uppercase;
          font-weight: $typo__fw--semibold;
          letter-spacing: 2px;
        }
      }
      &__controls {
        margin-top: 1em;
        display: flex;
      }
      &__icons {
        display: flex;
        margin-right: 20px;
      }
      &__icon {
        background-color: $clr__white;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        > i {
          color: $clrc-light-orange;
          font-size: 20px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &__switcher {
        display: flex;
        font-size: 1.8em;
        align-items: center;
        color: white;
        &-main {
          text-transform: uppercase;
          font-weight: $typo__fw--semibold;
        }
        &-button {
          font-size: 1.25em;
          color: white;
          transition: transform 0.3s;
          &.bn-icon--arrow-bordered-left {
            margin-right: 10px;
          }
          &.bn-icon--arrow-bordered-right {
            margin-left: 10px;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &__right {
        background-color: $clrc-light-green;
        color: $clr__white;
        padding: 0 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 21px;
        height: 100%;
        text-transform: uppercase;
        transition: background-color 0.5s;
        .bn-room-box__price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
          > div {
            width: 100%;
            white-space: nowrap;
          }
        }
        .bn-prenota {
          display: flex;
          align-items: center;
          > span {
            white-space: nowrap;
            margin-right: 15px;
          }
          > i  {
            font-size: 16px;
          }
        }
        &:hover {
          background-color: rgba($clrc-light-green, 0.8);
        }
      }
    }

    .bn-button.bn-toggleimage {
      @include button--default();
      height: 40px;
      line-height: 37px;
      margin-bottom: 10px;
      border-radius: 25px;
    }

    .bn-room-box__wrapper {
      display: flex;
      margin-top: 35px;
      &--1 {
        .bn-room-box__content {
          margin-right: 0;
          margin-left: 25px;
        }
        .bn-room-box__image {
          display: block;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .bn-room-box__headline {
      font-size: 4.6em;
      font-weight: $typo__fw--semibold;
      letter-spacing: 0;
      padding-right: 2em;
      line-height: 1;
    }

    .bn-room-box__content {
      width: 50%;
      margin-right: 25px;
      background-color: $clrc-light-background;
      padding: 30px 90px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .bn-room-box__images {
      width: 50%;
      overflow: hidden;
      position: relative;

      &.bn-hidden {
        display: none;
      }

      .bn-room-box__slider-container {
        position: relative;
        height: 100%;

        .bn-room-box__slider {
          width: 100%;
          height: 100%;

          .slick-list,
          .slick-track,
          .slick-slide > div {
            height: 100%;
          }

          .bn-room-box__image {
            overflow: hidden;
            height: 100%;

            img {
              @include img--responsive();
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .bn-room-box__arrows {
          @include slider--arrows($clr__white);
          left: 0;
          padding: 0 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 5;
        }

        .bn-room-box__dots {
          @include slider--dots($clr__white, 6);
          bottom: 20px;
          left: 20px;
          position: absolute;
          width: 100%;
          z-index: 5;
        }
      }

      .bn-room-box__visuals {
        left: 20px;
        position: absolute;
        top: 20px;
        z-index: 5;

        .bn-room-box__visual {
          display: block;
          margin-top: 10px;
          width: 60px;

          a {
            background-color: rgba($bg-visuals, 0.8);
            border-radius: 100%;
            color: $typo-visuals;
            height: 60px;
            display: block;
            text-align: center;

            .bn-icon {
              font-size: 3.5em;
              line-height: 60px;
            }

            &:visited {
              color: $typo-visuals;
            }

            &:hover {
              background-color: $bg-visuals;
              color: $typo-visuals;
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .bn-room-box__price {
        background-color: $clr__cta--primary;
        color: $typo-price;
        height: 150px;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 150px;
        z-index: 10;

        > div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 10px;
          position: relative;
          text-align: center;
        }

        .bn-room-box__amount-addition {
          font-size: 1.6em;
        }

        .bn-room-box__amount {
          font-size: 2.5em;
        }
      }
    }

    .bn-room-box__size {
      margin-top: 20px;

      span {
        font-size: 1.6em;
      }

      .bn-icon {
        font-size: 1.4em;
        margin-left: 5px;
      }
    }

    .bn-room-box__back {
      margin-bottom: 5em;
      margin-top: -6em;
      > a {
        text-transform: uppercase;
        font-size: 1.4em;
        color: $clrc-light-orange;
        font-weight: $typo__fw--semibold;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .bn-room-box__name {
      @include typo--headline($headline);
      text-transform: uppercase;
      text-align: left;
    }

    .bn-room-box__subtitle {
      @include typo--rte($clrc-dark-blue, $clrc-light-blue);
      margin-top: 2.5em;
    }

    .bn-room-box__description,
    .bn-room-box__information,
    .bn-room-box__features {
      margin-top: 20px;
    }

    .bn-room-box__description,
    .bn-room-box__information {
      @include typo--rte($description, $accent);
      font-size: $typo__fs--text;

      * {
        font-size: 1.0em !important;
      }
    }

    .bn-room-box__features {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin: 40px -10px 0 -10px;

      .bn-room-box__feature__box {
        width: 33.33%;
        padding: 10px;

        .bn-room-box__feature__wrap {
          border: 1px solid #E3DFD3;
          padding: 15px;
          align-items: center;
          display: flex;
        }

        .bn-icon {
          font-size: 3.5em;
          margin-right: 20px;
        }

        span {
          font-size: $typo__fs--text;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          line-height: 1;
        }
      }
    }

    .bn-room-box__rates,
    .bn-room-box__inquiry,
    .bn-room-box__buttons {
      margin-top: 40px;
    }

    .bn-room-box__rates {
      @include price-list--v1($description, $accent);
    }

    .bn-room-box__inquiry {
      .bn_request_variation__vertical {
        @include request--v1();
      }
    }

    .bn-room-box__buttons {
      display: flex;

      > div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-right: 15px;
        .bn-button {
          &.bn-button--inquire,
          &.bn-button--request,
          &.bn-button--book {
            min-width: 0 !important;
          }

          &.bn-button--inquire {
            @include button--cta();
            background-color: $clrc-light-blue;
            border-color: $clrc-light-blue;
            height: 40px;
            font-weight: $typo__fw--regular;
            &:hover {
              background-color: transparent;
              border-color: $clrc-light-blue !important;
              color: $clrc-light-blue !important;
            }
          }
          &.bn-button--request {
            @include button--cta();
            background-color: $clrc-light-orange;
            border-color: $clrc-light-orange;
            height: 60px;
            font-weight: $typo__fw--regular;
            font-size: 21px;
            .bn-icon {
              margin-left: 15px;
              font-size: 18px;
            }
            &:hover {
              background-color: transparent;
              border-color: $clrc-light-orange !important;
              color: $clrc-light-orange !important;
            }
          }
          &.bn-button--book {
            @include button--cta();
            background-color: $clrc-light-green;
            border-color: $clrc-light-green;
            height: 60px;
            font-weight: $typo__fw--regular;
            font-size: 21px;
            .bn-icon {
              margin-left: 15px;
              font-size: 18px;
            }
            &:hover {
              background-color: transparent;
              border-color: $clrc-light-green !important;
              color: $clrc-light-green !important;
            }
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // laptop
  @media #{$mq__laptop} {
    .bn-room-box {
      .bn-room-box__toolbar {
        height: 120px;
      }
      .bn-room-box__content {
        padding: 30px;
      }

    }
  }

  // tablet
  @media #{$mq__tablet} {
    .bn-room-box {
      .bn-room-box__toolbar {
        height: 130px;
        &__left {
          display: none;
        }
        &__infos  {
          font-size: 18px;
        }
        &__switcher {
          font-size: 1.5em;
        }
        &__right {
          padding: 0 15px;
          font-size: 18px;
        }
      }
      .bn-room-box__wrapper {
        flex-direction: column-reverse;
        margin-top: 2em;
        &--1 {
          flex-direction: column;
        }
      }

      .bn-room-box__headline {
        padding-right: 4em;
        font-size: 3.5em;
      }

      .bn-room-box__content {
        margin-right: 0;
        margin-left: 0 !important;
        width: 100%;
        margin-top: 2em;
        padding: 40px 15px;
      }

      .bn-room-box__images {
        width: 100%;
        .bn-room-box__price {
          height: 125px;
          min-width: 125px;
          right: 20px;
          top: 20px;

          .bn-room-box__amount {
            font-size: 2.0em;
          }
        }
      }

      .bn-room-box__features {
        .bn-room-box__feature__box {
          width: 50%;
          span {
            font-size: 1.3em;
          }
        }
      }

      .bn-room-box__buttons {
        > div {
          .bn-button {
            &.bn-button--request,
            &.bn-button--book {
              height: 50px;
              font-size: 16px;
              .bn-icon {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-room-box {
      .bn-room-box__toolbar {
        &__infos  {
          font-size: 16px;
        }
        &__switcher {
          font-size: 1.3em;
        }
        &__middle {
          padding: 0 15px;
        }
        &__right {
          padding: 0 15px;
          font-size: 16px;
        }
        &__icon {
          width: 30px;
          height: 30px;
          > i {
            font-size: 17px;
          }
        }
      }
      .bn-room-box__images {
        .bn-room-box__visuals {
          @include clearfix();

          .bn-room-box__visual {
            float: left;
            margin-left: 5px;
            margin-top: 0;
            width: 50px;

            a {
              height: 50px;

              .bn-icon {
                font-size: 2.5em;
                line-height: 50px;
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .bn-room-box__price {
          height: auto;
          min-width: 0;
          position: static;
          right: auto;
          top: auto;

          > div {
            display: block;
            height: auto;
          }

          .bn-room-box__amount-addition,
          .bn-room-box__amount {
            display: inline-block;
          }

          .bn-room-box__amount-addition {
            margin: 0 5px;
          }
        }
      }

      .bn-room-box__headline {
        padding-right: 0;
        font-size: 2.6em;
      }

      .bn-room-box__features {
        margin: 20px 0 0 0;

        .bn-room-box__feature__box {
          padding-left: 0;
          padding-right: 0;
          width: 100%;
        }
      }

      .bn-room-box__buttons {
        > div {
          margin-bottom: 20px;
          max-width: 100%;
          padding-left: 0;

          &:first-child {
            margin-bottom: 0;
            order: 3;
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-room-box {
      .bn-room-box__toolbar {
        &__right {
          display: none;
        }
      }
      .bn-room-box__images {
        .bn-room-box__slider-container {
          .bn-room-box__arrows {
            display: none;
          }

          .bn-room-box__dots {
            bottom: 10px;
            left: 10px;
          }
        }

        .bn-room-box__visuals {
          left: 10px;
          top: 10px;

          .bn-room-box__visual {
            width: 40px;

            a {
              height: 40px;

              .bn-icon {
                font-size: 2.0em;
                line-height: 40px;
              }
            }
          }
        }
      }

      .bn-room-box__content {
        .bn-room-box__buttons {
          > div {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
