/*  ==========================================================================
    IMPRESSIONS V5
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions-v5--arrows: $clr__ci--primary;
$module-clr__impressions-v5--arrows-hover: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-impressions-v5 {
  > div {
    position: relative;
  }
  .bn-impressions-v5__controls {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4em;
  }
  .bn-impressions-v5__arrows {
    @include slider--arrows-v5();
  }
  .pagination {
    background-color: $clr__white;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $clrc-light-blue;
    padding: 0 10px;
    margin-left: 1px;
    > span {
      text-transform: uppercase;
      font-size: 1.4em;
      font-weight: $typo__fw--semibold;
    }
  }
  .bn-impressions-v5__link {
    background-color: $clr__white;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $clrc-light-blue;
    padding: 0 10px;
    margin-left: 1px;
    > i {
      display: inline-block;
      font-size: 1.5em;
      margin-left: 10px;
    }
    > span {
      text-transform: uppercase;
      font-size: 1.4em;
      font-weight: $typo__fw--semibold;
    }
    &:hover {
      background-color: $clrc-light-blue;
      color: $clr__white;
    }
  }
  .bn-impressions-v5__head{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    &.bn-titleset{
      justify-content: space-between;
    }
  }
  .bn-impressions-v5__title{
    text-transform: uppercase;
    font-size: 3em;
    font-weight: $typo__fw--regular;
    padding-left: 10px;
  }
  .bn-impressions-v5__slider {
    @include clearfix();
    overflow: hidden;
    .bn-impressions-v5__image {
      float: left;
      display: block;
      width: 100%;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-impressions-v5 {
    .bn-impressions-v5__title{
      font-size: 2em;
    }
    .bn-impressions-v5__slider {
      .bn-impressions-v5__image {
        padding: 0 5px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-impressions-v5 {
    .bn-impressions-v5__slider {
      .bn-impressions-v5__image {
        float: none;
        padding: 0;
        width: 100%;
      }
    }
  }
}

@media  (max-width: 350px){
  .bn-impressions-v5 {
    .bn-impressions-v5__title{
      font-size: 1.8em;
    }
  }
}