/*  ==========================================================================
    LINKBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-linkboxes {
  background-color: #FCF9F4;
  > div {
    flex-direction: column;
  }
  &__overline {
    align-items: center;
    margin-bottom: 2em;
  }
  .bn-linkboxes__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-linkboxes__item {
      display: inline-block;
      position: relative;
      width: calc(25% - 10px);
      .bn-linkboxes__image {
        display: block;
        margin-bottom: 10px;
        position: relative;
        > div {
          overflow: hidden;
        }
        img {
          @include img--responsive();
        }
        &:before {
          font-family: 'bn-icons' !important;
          content: "\e987";
          font-size: 5em;
          color: $clr__white;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          background-color: rgba($clrc-dark-blue, 0.7);
          opacity: 0;
          transition: opacity 0.5s;
        }
      }
      .bn-linkboxes__title {
        text-transform: uppercase;
        > span {
          font-size: 2.1em;
          color: $clr__typo--primary;
        }
      }
      &:hover {
        .bn-linkboxes__image {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: calc(50% - 15px);
        margin: 30px 0;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: 100%;
      }
    }
  }
}
