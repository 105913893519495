/*  ==========================================================================
    ROUTE PLANNER
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__route-planner--border: $clr__gray;
$module-clr__route-planner--map-bg: $clr__black;
$module-clr__route-planner--text: $clr__typo--primary;

/*
 * MODULE
 */

.bn-route-planner {
  > .bn-grid__row {
    > .bn-grid__col {
      > div {
        position: relative;
      }
    }
  }
  .bn-route-planner__map {
    background-color: $module-clr__route-planner--map-bg;
    height: 660px;
    position: relative;
    .bn-loader {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .bn-route-planner__form {
    @include typo--form($module-clr__route-planner--text);
    display: flex;
    position: absolute;
    top: 660px;
    left: 50%;
    transform: translate(-50%, -100%);
    .bn-route-planner__start {
      display: block;
      height: 50px;
      line-height: 48px;
      padding: 0 10px;
      width: 100%;
    }
    .bn-route-planner__submit {
      @include button--default();
      width: 100%;
      display: block;
      transition: background-color 0.5s;
      padding: 0;
      &:hover {
        background-color: rgba($clrc-light-blue, 0.7);
        color: $clr__white;
      }
    }
  }
  .bn-route-planner__controls {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    justify-content: center;
    .bn-route-planner__directions {
      @include scrollbar($clr__scrollbar,5px);
      font-size: 16px;
      overflow-y: auto;
      padding-left: 10px;
      width: 70%;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-route-planner {
    .bn-route-planner__map {
      height: 400px;
    }
    .bn-route-planner__form {
      top: 400px;
    }
    .bn-route-planner__controls {
      .bn-route-planner__form {
        width: 40%;
      }
      .bn-route-planner__directions {
        width: 60%;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-route-planner {
    .bn-route-planner__map {
      height: 300px;
    }
    .bn-route-planner__form {
      top: 300px;
      flex-direction: column;
    }
    .bn-route-planner__controls {
      display: block;
      .bn-route-planner__form,
      .bn-route-planner__directions {
        width: 100%;
      }
      .bn-route-planner__form {
        border-right: 0;
        padding-right: 0;
      }
      .bn-route-planner__directions {
        font-size: 14px;
        padding-left: 0;
        padding-top: 10px;
      }
    }
  }
}