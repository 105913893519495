/*  ==========================================================================
    MOBILE HEADER V1
    ========================================================================== */

@mixin mobile-header--v1() {
  border-top: 3px solid $clrc-light-green;
  border-bottom: 1px solid rgba($clr__white, 0.2);
  height: $unit__hgt--mobile-header-v1;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $unit__zi--mobile-header;
  transition: background-color 0.5s, border-bottom-color 0.5s;
  // controls
  .bn-controls {
    height: 100%;
    > .bn-grid__row {
      height: 100%;
      padding: 0 2.5em;
    }
    .bn-controls__left {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $clr__white;
      .bn-controls__left___top {
        > .bn-grid__col {
        }
      }
      .bn-controls__left___bottom {
        > .bn-grid__col {
        }
      }
    }
    .bn-controls__right {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      display: inline-block;
      margin-right: 3.5em;
      ul {
        @include clearfix();
        font-size: 1.4em;
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '-';
          }
          a {
            color: $clr__white;
            padding: 0 5px;
            font-weight: $typo__fw--bold;
            &:visited {
              color: $clr__white;
            }
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clrc-light-green;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // burger menu
  .bn-burger-menu {
    cursor: pointer;
    display: inline-block;
    font-size: 3em;
  }
  // control bar
  .bn-control-bar {
    @include mobile-header--control-bar();
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: inline-block;
      .bn-main-logo__image {
        img {
          max-height: 90px;
          max-width: 250px;
          display: block;
        }
        &.bn-main-logo__image--big,
        &.bn-main-logo__image--big--novista,
        &.bn-main-logo__image--small {
          display: none;
        }
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized,
  &.bn-mobile-header--no-vista {
    background-color: rgba($clrc-river-bed, 0.9);
    // controls
    .bn-controls {
      .bn-controls__left {
        .bn-controls__left___bottom {
          height: auto;
          > .bn-grid__col {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phone
  @media #{$mq__phone} {
    height: $unit__hgt--mobile-header-v1-phone;
    // controls
    .bn-controls {
      .bn-controls__left {
        .bn-controls__left___top {
          display: none;
        }
        .bn-controls__left___bottom {
          > .bn-grid__col {
            justify-content: center;
            padding-bottom: 0;
          }
        }
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            max-height: 60px;
            max-width: 180px;
          }
        }
      }
    }
  }
}
